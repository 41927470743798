// import _newArrowCheck from "@babel/runtime/helpers/newArrowCheck";
import { ApolloLink } from "@apollo/client/core";
import { send, toObservable, unobserveOrCancel } from "@absinthe/socket";
import { compose } from "lodash/fp";
import { print } from "graphql";

// const _this = undefined;

function unobserveOrCancelIfNeeded(absintheSocket, notifier, observer) {
  // _newArrowCheck(this, _this);

  if (notifier && observer) {
    unobserveOrCancel(absintheSocket, notifier, observer);
  }
}

function notifierToObservable(absintheSocket, onError, onStart) {
  // const _this2 = this;

  // _newArrowCheck(this, _this);

  return (notifier) => {
    // _newArrowCheck(this, _this2);

    return toObservable(absintheSocket, notifier, {
      onError: onError,
      onStart: onStart,
      unsubscribe: unobserveOrCancelIfNeeded,
    });
  };
}

function getRequest(_ref) {
  const query = _ref.query,
    constiables = _ref.constiables;

  // _newArrowCheck(this, _this);

  return {
    operation: print(query),
    constiables: constiables,
  };
}
/**
 * Creates a terminating ApolloLink to request operations using given
 * AbsintheSocket instance
 */

function createAbsintheSocketLink(absintheSocket, onError, onStart) {
  // const _this3 = this;

  // _newArrowCheck(this, _this);

  return new ApolloLink(
    compose(
      notifierToObservable(absintheSocket, onError, onStart),
      (request) => {
        // _newArrowCheck(this, _this3);

        return send(absintheSocket, request);
      },
      getRequest
    )
  );
}

export default createAbsintheSocketLink;
