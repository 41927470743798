import { AuthState } from "@eyr-mobile/domain/Auth/Auth.constants";
import { getOrderRoutePath } from "@eyr-mobile/domain/Order";

export function getInitialRouteNameFromAuthState(authState) {
  switch (authState) {
    case AuthState.LOCKED:
      return "SessionRecoveryScreen";
    case AuthState.AUTHENTICATED:
      return "AuthenticatedTabNavigatorScreen";
    case AuthState.UNAUTHENTICATED:
      return "HomeScreen";
    default:
  }
}

export const routes = {
  screens: {
    HomeScreen: "sign-in",
    IdentificationScreen: "identification",
    IdentificationCountryScreen: "identification/countries",
    IdentificationMethodScreen: "identification/country/:countryCode",
    AuthenticatedTabNavigatorScreen: {
      path: "",
      screens: {
        HomeTabScreen: {
          path: "",
          parse: {
            productId: Number,
          },
        },
        InboxTabScreen: "inbox",
        MyAccountTabScreen: "account",
      },
    },
    SessionRecoveryScreen: "session-recovery",
    AccountConsultationsHistoryScreen: "treatment/history",
    AccountPaymentMethodsScreen: "account/payment-methods",
    AddAccountInsuranceOrDiscountScreen:
      "account/payment-methods/add/insurance-or-membership/:slug",
    ConsultationReviewScreen: "review/consultation",
    ContentProgramsScreen: "content/programs",
    ContextualDocumentsScreen: "treatment/documents",
    ConversationScreen: "conversation",
    DeleteMyAccountScreen: "account/delete",
    DeviceRegistrationScreen: "device-registration",
    DigitalToolFormScreen: "treatment-tool/form",
    DigitalToolsScreen: "treatment-tools",
    DocumentsScreen: "documents",
    EditAccountConsentsScreen: "account/manage-consents",
    EditAccountContactInfoScreen: "account/contact-info",
    EditAccountBankCardScreen: "account/payment-methods/add/card",
    EditAccountInsuranceOrMembershipScreen:
      "account/payment-methods/select/insurance-or-membership",
    EditAccountLanguageScreen: "account/language",
    EnableAppleReviewModeScreen: "marketplace-review-protection",
    // domain/Order START
    FlowStageAttachFormScreen: getOrderRoutePath("attach-form"),
    FlowStageSelectClientScreen: getOrderRoutePath("select-client"),
    FlowStageSelectClient_AddChildScreen: getOrderRoutePath(
      "select-client/add-child"
    ),
    FlowStageSetMedicalCategoryScreen: getOrderRoutePath(
      "set-medical-category"
    ),
    FlowStageSetMedicalCategory_DescribeSymptomsScreen: {
      path: getOrderRoutePath(
        "set-medical-category/:medicalCategoryId/describe-symptoms"
      ),
      parse: { medicalCategoryId: Number },
    },
    FlowStageSetPaymentMethodsScreen: getOrderRoutePath("set-payment-methods"),
    FlowStageSetPaymentMethods_PrimaryScreen: getOrderRoutePath(
      "set-payment-methods/primary"
    ),
    FlowStageSetPaymentMethods_SecondaryScreen: {
      path: getOrderRoutePath(
        "set-payment-methods/:primaryPaymentMethodId/secondary"
      ),
      parse: { primaryPaymentMethodId: Number },
    },
    FlowStageSetPaymentMethods_AddCreditCardScreen: getOrderRoutePath(
      "set-payment-methods/add/card"
    ),
    FlowStageSetPaymentMethods_SelectInsuranceOrMembershipScreen:
      getOrderRoutePath("set-payment-methods/select/insurance-or-membership"),
    FlowStageSetPaymentMethods_AddInsuranceOrDiscountScreen: getOrderRoutePath(
      "set-payment-methods/add/insurance-or-membership/:slug"
    ),
    FlowStageSetPaymentMethods_ControlQuestionTravelCountryScreen: {
      path: getOrderRoutePath(
        "set-payment-methods/verify/:controlPaymentMethodId/travel-destination-country"
      ),
      parse: { controlPaymentMethodId: Number },
    },
    FlowStageSetPaymentMethods_ControlQuestionTravelScreen: {
      path: getOrderRoutePath(
        "set-payment-methods/verify/:controlPaymentMethodId/travel-destination"
      ),
      parse: { controlPaymentMethodId: Number },
    },
    FlowStageSetPaymentMethods_ControlQuestionWorkScreen: {
      path: getOrderRoutePath(
        "set-payment-methods/verify/:controlPaymentMethodId/work-place"
      ),
      parse: { controlPaymentMethodId: Number },
    },
    FlowStageSetPaymentMethods_ControlQuestionChildScreen: {
      path: getOrderRoutePath(
        "set-payment-methods/verify/:controlPaymentMethodId/child"
      ),
      parse: { controlPaymentMethodId: Number },
    },
    FlowStageSpecifyAppointmentInformationScreen: getOrderRoutePath(
      "specify-appointment-information"
    ),
    FlowStageSpecifyAppointmentInformation_SetProviderAndTimeScreen:
      getOrderRoutePath(
        "specify-appointment-information/set-provider-and-time"
      ),
    CheckoutScreen: getOrderRoutePath("checkout"),
    CheckoutResultScreen: getOrderRoutePath("checkout/result"),
    DisabledPaymentMethodResolutionScreen: getOrderRoutePath(
      "disabled-payment-method-resolution"
    ),
    // domain/Order END
    HelpAndContactScreen: "help-and-contact",
    HowItWorksScreen: "how-it-works",
    IncomingCallScreen: "incoming-call",
    OnboardingAddAccountContactInfoScreen: "onboarding/add/contact-info",
    OnboardingAddInsuranceOrDiscountScreen:
      "onboarding/add/insurance-or-membership/:slug?",
    OnboardingSelectInsuranceOrDiscountScreen:
      "onboarding/select/insurance-or-membership",
    OngoingCallScreen: "ongoing-call",
    PharmaciesScreen: "pharmacies",
    SplashScreen: "splash",
    TermsAndConditionsScreen: "terms-and-conditions",
    VaccinationCatalogScreen: "vaccines",
  },
};
