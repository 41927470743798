import React, { useCallback, useEffect, useMemo } from "react";
import {
  View,
  ScrollView,
  RefreshControl,
  Linking,
  Platform,
} from "react-native";
import { useIntl, RawIntlProvider, IntlConfig } from "@eyr-mobile/core/Intl";
import { useNavigation, useRoute } from "@react-navigation/native";
import {
  some,
  isEmpty,
  includes,
  pick,
  get,
  thru,
  flow,
  find,
  size,
} from "lodash/fp";
import {
  useMutation,
  useQuery,
  withHandlers,
  useSubscription,
} from "@eyr-mobile/core/DataProvider";
import { renderIf } from "@eyr-mobile/core/Lib";
import {
  getSessionTrackingUserIdFromAccount,
  setSessionTrackingUserId,
} from "@eyr-mobile/core/SessionTracking";
import { useConfigValue } from "@eyr-mobile/core/Config";
import {
  DismissOrder,
  GetHomeTabScreenData,
  ProductType,
  ProductSubtype,
  ServicesUpdatedSubscription,
  ProductsUpdatedSubscription,
  OrderAnalyticsContentType,
  defaultCurrencyDisplayRules,
  OrderLinkingActions,
  useInitOrder,
} from "@eyr-mobile/domain/Order";
import { useAlert } from "@eyr-mobile/core/Alert";
import { useDevice } from "@eyr-mobile/core/Device";
import {
  LOGGER_LEVEL_WARN,
  LOGGER_LEVEL_ERROR,
  LoggerFactory,
} from "@eyr-mobile/core/Logger";
import {
  setMarketingUserId,
  setAnalyticsUserId,
  logSelectContent,
} from "@eyr-mobile/core/Analytics";
import {
  logToErrorTracking,
  setErrorTrackingUserId,
} from "@eyr-mobile/core/ErrorTracking";
import { AppState, useAppState } from "@eyr-mobile/core/AppState";
import { getLinkingURL } from "@eyr-mobile/core/Linking";

import {
  EyrButton,
  ProductsCatalog,
  ServicesList,
  ConsultationPreparationInfo,
  Alert,
  Heading,
  Subtitle,
  Paragraph,
  IconContainer,
  Card,
  BannerImage,
  CancellationInfo,
  UnorderedList,
} from "../../components";
import { SVGs } from "../../res";
import { getTabBarIconFn } from "../../Navigation/Navigation.helpers";
import howItWorks from "../../res/images/howItWorks.jpg";

import { styles } from "./HomeTabScreen.styles";
import { messages } from "./HomeTabScreen.messages";

const TAG = "HomeTabScreen";
const logger = LoggerFactory.get(`screens/${TAG}`);

function handleWebAppUrlPress() {
  return Linking.openURL(getLinkingURL());
}

const hasNoContactInfoIssue = (account) =>
  isEmpty(account.phoneNumber) || isEmpty(account.email);
const hasNoInsuranceIssue = (accountPaymentMethods) =>
  !some({ type: "INSURANCE" }, accountPaymentMethods);
const getFormattablePriceForProduct = (subtype, products) => {
  return flow([
    find({ subtype }),
    get("price"),
    thru(({ amount, currency }) => [
      amount,
      {
        currency,
        ...defaultCurrencyDisplayRules,
      },
    ]),
  ])(products);
};

const extraInfoEnabledFor = [
  ProductSubtype.ASSISTED_SELF_HELP,
  ProductSubtype.ASSISTED_SELF_HELP_FOLLOWUP,
  ProductSubtype.COUPLE_INITIAL_CONSULTATION,
  ProductSubtype.COUPLE_FOLLOWUP_CONSULTATION,
];

export function HomeTabScreen() {
  const intl = useIntl();
  const { formatMessage, locale, formatNumber } = intl;
  const { navigate, setParams } = useNavigation();
  const { screenSizeSelect } = useDevice();
  const alert = useAlert();
  const route = useRoute();
  const routeParams = route?.params || {};

  const { action: linkingAction, productId: linkingProductId } = routeParams;
  const [initOrder] = useInitOrder();

  const onDataLoaded = useCallback(
    (data) =>
      logToErrorTracking(
        `GetHomeTabScreenData:response:${JSON.stringify(
          pick(["products", "services", "accountPaymentMethods"], data)
        )}`
      ),
    []
  );

  const {
    refreshing,
    handlers,
    data: {
      products = [],
      services = [],
      account = {},
      accountPaymentMethods = [],
    } = {},
    data,
    refetch,
  } = withHandlers(
    useQuery(GetHomeTabScreenData, {
      onCompleted: onDataLoaded,
    })
  );

  const refetchOnActiveFromBackground = useCallback(
    (previousAppState) => {
      if (previousAppState !== AppState.BACKGROUND) {
        return;
      }
      refetch();
    },
    [refetch]
  );

  useAppState({
    skip: Platform.OS === "web",
    onActive: refetchOnActiveFromBackground,
  });
  const showNoContactIssue = useMemo(
    () => hasNoContactInfoIssue(account),
    [account]
  );
  const dashboardWarning = useConfigValue("dashboard_warning");
  const parsedDashboardWarning = useMemo(() => {
    if (!dashboardWarning) {
      return;
    }
    try {
      const parsed = JSON.parse(dashboardWarning);
      return parsed[locale] || parsed[IntlConfig.defaultLocale];
    } catch (error) {
      logger("error parsing dashboard_warning", error, LOGGER_LEVEL_WARN);
    }
  }, [dashboardWarning, locale]);
  const dashboardBanner = useConfigValue("dashboard_banner");
  const parsedDashboardBanner = useMemo(() => {
    if (!dashboardBanner) {
      return;
    }
    try {
      const parsed = JSON.parse(dashboardBanner);
      return parsed[locale] || parsed[IntlConfig.defaultLocale];
    } catch (error) {
      logger("error parsing dashboard_banner", error, LOGGER_LEVEL_WARN);
    }
  }, [dashboardBanner, locale]);

  useEffect(() => {
    if (!account.customerUserId) {
      return;
    }
    setMarketingUserId(account.customerUserId);
  }, [account.customerUserId]);

  const showNoInsuranceIssue = useMemo(
    () => hasNoInsuranceIssue(accountPaymentMethods),
    [accountPaymentMethods]
  );

  const handleEditAccountPaymentMethods = useCallback(() => {
    navigate("AccountPaymentMethodsScreen");
  }, [navigate]);

  const handleEditContactInformation = useCallback(() => {
    navigate("EditAccountContactInfoScreen");
  }, [navigate]);
  useEffect(() => {
    const sessionTrackingUserId = getSessionTrackingUserIdFromAccount(account);
    if (sessionTrackingUserId) {
      setSessionTrackingUserId(sessionTrackingUserId);
    }
  }, [account]);

  useEffect(() => {
    if (account.slug) {
      setAnalyticsUserId(account.slug);
      setErrorTrackingUserId(account.slug);
    }
  }, [account.slug]);

  useSubscription(ServicesUpdatedSubscription, {
    onData: useCallback(({ data: subscriptionData, client }) => {
      logToErrorTracking(
        `ServicesUpdatedSubscription:update:${JSON.stringify(
          subscriptionData.data
        )}`
      );
      client.writeQuery({
        query: GetHomeTabScreenData,
        data: {
          ...client.readQuery({ query: GetHomeTabScreenData }),
          services: subscriptionData.data.servicesUpdated,
        },
      });
    }, []),
  });

  useSubscription(ProductsUpdatedSubscription, {
    onData({ data: subscriptionData, client }) {
      logToErrorTracking(
        `ProductsUpdatedSubscription:update:${JSON.stringify(
          subscriptionData.data
        )}`
      );
      client.writeQuery({
        query: GetHomeTabScreenData,
        data: {
          ...client.readQuery({ query: GetHomeTabScreenData }),
          products: subscriptionData.data.productsUpdated,
        },
      });
    },
  });

  const onProductSelected = useCallback(
    (item) => {
      const { id, __typename } = item;
      if (__typename === "Product") {
        initOrder(item);
      } else if (__typename === "SubCatalog") {
        navigate(id);
      }
    },
    [initOrder, navigate]
  );

  const getReadMoreContentForProduct = useCallback(
    (product) => {
      if (
        includes(product.subtype, [
          ProductSubtype.ASSISTED_SELF_HELP,
          ProductSubtype.ASSISTED_SELF_HELP_FOLLOWUP,
        ])
      ) {
        return (
          <>
            <View style={styles.introContainer}>
              <Paragraph size="l" spacing="l">
                {formatMessage(
                  messages.mentalSelfHelpReadMoreIntroductionFirstParagraph
                )}
              </Paragraph>
            </View>
            <View style={styles.USPContainer}>
              <UnorderedList
                listItemIcon={
                  <View style={styles.listItemIconContainer}>
                    <IconContainer size="xxs" variant="plain">
                      <SVGs.CheckCircleMono />
                    </IconContainer>
                  </View>
                }
              >
                <Paragraph size="l" spacing="m" wrappable>
                  {formatMessage(messages.mentalSelfHelpReadMoreUSPNumberOne)}
                </Paragraph>
                <Paragraph size="l" spacing="m" wrappable>
                  {formatMessage(messages.mentalSelfHelpReadMoreUSPNumberTwo)}
                </Paragraph>
                <Paragraph size="l" spacing="m" wrappable>
                  {formatMessage(messages.mentalSelfHelpReadMoreUSPNumberThree)}
                </Paragraph>
                <Paragraph size="l" spacing="m" wrappable>
                  {formatMessage(messages.mentalSelfHelpReadMoreUSPNumberFour)}
                </Paragraph>
                <Paragraph size="l" wrappable>
                  {formatMessage(messages.mentalSelfHelpReadMoreUSPNumberFive)}
                </Paragraph>
              </UnorderedList>
            </View>
            <View style={styles.conditionsContainer}>
              <Subtitle size="l" spacing="s">
                {formatMessage(messages.mentalSelfHelpReadMoreConditionsTitle)}
              </Subtitle>
            </View>
            <View style={styles.conditionsParagraphContainer}>
              <Paragraph size="l" spacing="s" wrappable>
                {formatMessage(
                  messages.mentalSelfHelpReadMoreConditionsFirstParagraph
                )}
              </Paragraph>
              <Subtitle size="l" spacing="s">
                {formatNumber(
                  ...getFormattablePriceForProduct(
                    ProductSubtype.ASSISTED_SELF_HELP,
                    products
                  )
                )}
              </Subtitle>
            </View>
            <View style={styles.conditionsParagraphContainer}>
              <Paragraph size="l" spacing="l">
                {formatMessage(
                  messages.mentalSelfHelpReadMoreConditionsSecondParagraph
                )}
              </Paragraph>
              <Subtitle size="l" spacing="l">
                {formatNumber(
                  ...getFormattablePriceForProduct(
                    ProductSubtype.ASSISTED_SELF_HELP_FOLLOWUP,
                    products
                  )
                )}
              </Subtitle>
            </View>
            <Paragraph size="l">
              {formatMessage(messages.mentalSelfHelpReadMoreDisclaimer)}
            </Paragraph>
          </>
        );
      }
      if (
        includes(product.subtype, [
          ProductSubtype.COUPLE_INITIAL_CONSULTATION,
          ProductSubtype.COUPLE_FOLLOWUP_CONSULTATION,
        ])
      ) {
        return (
          <>
            <View style={styles.introContainer}>
              <Paragraph size="l" spacing="l">
                {formatMessage(
                  messages.couplesTherapyReadMoreIntroductionFirstParagraph
                )}
              </Paragraph>
            </View>
            <View style={styles.conditionsParagraphContainer}>
              <Paragraph size="l" spacing="l">
                {formatMessage(
                  messages.couplesTherapyReadMoreConditionsSecondParagraph,
                  {
                    boldWord: (
                      <Subtitle size="l">
                        {formatMessage(
                          messages.couplesTherapyReadMoreConditionsBoldWord
                        )}
                      </Subtitle>
                    ),
                    underlinedWord: (
                      <Paragraph
                        size="l"
                        decoration="underline"
                        onPress={handleWebAppUrlPress}
                      >
                        {formatMessage(
                          messages.couplesTherapyReadMoreConditionsUnderlinedWord
                        )}
                      </Paragraph>
                    ),
                  }
                )}
              </Paragraph>
            </View>
            <View style={styles.USPContainer}>
              <UnorderedList
                listItemIcon={
                  <View style={styles.listItemIconContainer}>
                    <IconContainer size="xxs" variant="plain">
                      <SVGs.CheckCircleMono />
                    </IconContainer>
                  </View>
                }
              >
                <Paragraph size="l" spacing="m" wrappable>
                  {formatMessage(messages.couplesTherapyReadMoreUSPNumberOne)}
                </Paragraph>
                <Paragraph size="l" spacing="m" wrappable>
                  {formatMessage(messages.couplesTherapyReadMoreUSPNumberTwo)}
                </Paragraph>
                <Paragraph size="l" spacing="m" wrappable>
                  {formatMessage(messages.couplesTherapyReadMoreUSPNumberThree)}
                </Paragraph>
                <Paragraph size="l" spacing="m" wrappable>
                  {formatMessage(messages.couplesTherapyReadMoreUSPNumberFour)}
                </Paragraph>
                <Paragraph size="l" wrappable>
                  {formatMessage(messages.couplesTherapyReadMoreUSPNumberFive)}
                </Paragraph>
              </UnorderedList>
            </View>
            <View style={styles.conditionsContainer}>
              <Subtitle size="l" spacing="s">
                {formatMessage(messages.couplesTherapyReadMoreConditionsTitle)}
              </Subtitle>
            </View>
            <View style={styles.conditionsParagraphContainer}>
              <Paragraph size="l" spacing="l" wrappable>
                {formatMessage(
                  messages.couplesTherapyReadMoreConditionsFirstParagraph
                )}
              </Paragraph>
              <View style={styles.conditionsParagraphPriceContainer}>
                <Subtitle size="l" spacing="l">
                  {formatNumber(
                    ...getFormattablePriceForProduct(
                      ProductSubtype.COUPLE_INITIAL_CONSULTATION,
                      products
                    )
                  )}
                </Subtitle>
              </View>
            </View>
          </>
        );
      }
    },
    [formatMessage, formatNumber, products]
  );

  const onExtraInfoSelected = useCallback(
    (product) => {
      logSelectContent({
        content_type: OrderAnalyticsContentType.PRODUCT_EXTRA_INFO,
        content_id: `${product.type}:${product.subtype}:${product.id}`,
      });
      alert({
        heading: product.name,
        showHeaderDivider: true,
        showCloseButton: true,
        buttons: [
          {
            title: formatMessage(messages.readMoreActionTitle),
            onPress: () => onProductSelected(product),
            accessibilityLabel: `Book ${product.name} consultation`,
          },
        ],
        buttonsContainerPosition: "bottomFixed",
        variant: "bottomSheet",
        paddingVerticalSize: "s",
        paddingHorizontalSize: "s",
        size: "l",
        children: getReadMoreContentForProduct(product),
      });
    },
    [alert, formatMessage, getReadMoreContentForProduct, onProductSelected]
  );

  const onIntroActionPressed = useCallback(() => {
    navigate("HowItWorksScreen");
  }, [navigate]);

  const onDigitalToolSelected = useCallback(
    (digitalTool) => {
      switch (digitalTool.__typename) {
        case "DigitalToolForm":
          navigate("DigitalToolFormScreen", digitalTool);
          break;
        default:
          break;
      }
    },
    [navigate]
  );

  const handleFindPharmacyRequested = useCallback(() => {
    navigate("PharmaciesScreen");
  }, [navigate]);

  const [dismissOrder] = useMutation(DismissOrder);

  const onServiceDismissed = useCallback(
    (service) => {
      dismissOrder({
        variables: {
          input: { id: service.order.id },
        },
      });
    },
    [dismissOrder]
  );

  const handleGoToApoproOnlinePharmacy = useCallback(
    () => Linking.openURL("https://apopro.dk/eyr"),
    []
  );

  const handleShowServiceOrderCancellationInfo = useCallback(
    (service) => {
      alert({
        size: "l",
        title: formatMessage(messages.cancellationModalTitle),
        showCloseButton: true,
        children: (
          <RawIntlProvider value={intl}>
            <View style={styles.modalContentTopSpace} />
            <CancellationInfo service={service} />
          </RawIntlProvider>
        ),
      });
    },
    [formatMessage, alert, intl]
  );

  const handleShowServiceOrderPreparationInfo = useCallback(
    (service) => {
      const hasInfo = ![
        "AppointmentConsultationService",
        "DropInConsultationService",
        "PatientGuideService",
      ].includes(service.__typename);
      if (hasInfo) {
        return;
      }

      const closeAlert = alert({
        size: "l",
        title: formatMessage(messages.preparationInfoModalTitle),
        showCloseButton: true,
        children: (
          <RawIntlProvider value={intl}>
            <View style={styles.modalContentTopSpace} />
            <ConsultationPreparationInfo
              showChildSection={!service.forMe}
              showAddPhoneNumberSection={isEmpty(account.phoneNumber)}
              onAddPhoneNumberButtonPressed={() => {
                closeAlert();
                navigate("EditAccountContactInfoScreen");
              }}
            />
          </RawIntlProvider>
        ),
      });
    },
    [navigate, formatMessage, alert, intl, account.phoneNumber]
  );

  const handleDashboardWarningAction = useCallback(
    async ({ __typename, target }) => {
      if (__typename === "AlertActionExternalLink") {
        if (await Linking.canOpenURL(target)) {
          await Linking.openURL(target);
        }
      }
      if (__typename === "AlertActionInternalLink") {
        navigate(target);
      }
    },
    [navigate]
  );

  const handlePressDashboardBannerButton = useCallback(async () => {
    const { __typename, target } = parsedDashboardBanner.button;
    if (__typename === "AlertActionExternalLink") {
      if (await Linking.canOpenURL(target)) {
        await Linking.openURL(target);
      }
    }
    if (__typename === "AlertActionInternalLink") {
      navigate(target);
    }
  }, [navigate, parsedDashboardBanner]);

  const navigateToContextualDocumentsScreen = useCallback(
    (service, params = {}) => {
      navigate("ContextualDocumentsScreen", {
        serviceName: service.name,
        treatmentPlanId: service.treatmentPlanId,
        ...params,
      });
    },
    [navigate]
  );

  const handleServiceSelected = useCallback(
    (service) => {
      if (service.__typename === "PatientGuideService") {
        const followUpProduct = find(
          { subtype: ProductSubtype.GUIDE_FOLLOWUP_CONSULTATION },
          products
        );
        if (!followUpProduct) {
          logger(
            "cannot find available product of same type",
            ProductType.GUIDE_CONSULTATION,
            LOGGER_LEVEL_ERROR
          );
          return;
        }
        onProductSelected(followUpProduct);
      }
    },
    [onProductSelected, products]
  );

  const linkingProduct = useMemo(
    () => find({ id: linkingProductId }, products),
    [linkingProductId, products]
  );
  useEffect(() => {
    if (linkingAction === OrderLinkingActions.INIT_ORDER && linkingProduct) {
      onProductSelected(linkingProduct);
      setParams({ action: undefined, productId: undefined });
    }
  }, [linkingAction, linkingProduct, onProductSelected, setParams]);

  useEffect(() => {
    if (
      linkingAction === OrderLinkingActions.VIEW_EXTRA_INFO &&
      linkingProduct
    ) {
      if (includes(linkingProduct.subtype, extraInfoEnabledFor)) {
        onExtraInfoSelected(linkingProduct);
      }
      setParams({ action: undefined, productId: undefined });
    }
  }, [linkingAction, linkingProduct, onExtraInfoSelected, setParams]);

  return (
    <View style={styles.container}>
      {renderIf(parsedDashboardWarning)(() => (
        <Alert
          responsive
          collapsible
          {...parsedDashboardWarning}
          onAction={handleDashboardWarningAction}
        />
      ))}
      {(!data && handlers) || (
        <ScrollView
          contentContainerStyle={screenSizeSelect({
            xs: styles.contentContainerXS,
            s: styles.contentContainerS,
            m: styles.contentContainerM,
          })}
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={refetch} />
          }
        >
          {renderIf(parsedDashboardBanner)(() => (
            <View style={styles.sectionContainer}>
              <View
                style={screenSizeSelect({
                  xs: styles.bannerContainerXS,
                  s: styles.bannerContainerS,
                })}
              >
                <View style={styles.bannerContentContainer}>
                  <Subtitle size="l" spacing="s" color="secondary">
                    {parsedDashboardBanner.title}
                  </Subtitle>
                  <Paragraph color="secondary">
                    {parsedDashboardBanner.body}
                  </Paragraph>
                </View>
                {renderIf(parsedDashboardBanner.button)(() => (
                  <View
                    style={screenSizeSelect({
                      xs: styles.buttonContainerXS,
                      s: styles.buttonContainerS,
                    })}
                  >
                    <EyrButton
                      onPress={handlePressDashboardBannerButton}
                      variant="secondary"
                      title={parsedDashboardBanner.button.title}
                    />
                  </View>
                ))}
              </View>
            </View>
          ))}
          {renderIf(size(services) !== 0)(() => (
            <View style={styles.sectionContainer}>
              <>
                <Heading spacing="l">
                  {formatMessage(messages.ordersSectionTitle)}
                </Heading>
                <ServicesList
                  onServiceSelected={handleServiceSelected}
                  services={services}
                  onPreparationInfoForServiceRequested={
                    handleShowServiceOrderPreparationInfo
                  }
                  onDigitalToolSelected={onDigitalToolSelected}
                  onFindPharmacyRequested={handleFindPharmacyRequested}
                  onServiceDismissed={onServiceDismissed}
                  onCancellationInfoForServiceRequested={
                    handleShowServiceOrderCancellationInfo
                  }
                  onDocumentListForServiceRequested={
                    navigateToContextualDocumentsScreen
                  }
                />
              </>
            </View>
          ))}
          <View style={styles.sectionContainer}>
            {renderIf(size(services) === 0)(() => (
              <Paragraph spacing="s" alignment="center" size="l">
                {formatMessage(messages.userGreeting, {
                  firstName: account.firstName,
                })}
              </Paragraph>
            ))}
            <Heading spacing="l" alignment="center">
              {formatMessage(messages.productsSectionTitle)}
            </Heading>
            <ProductsCatalog
              products={products}
              extraInfoEnabledFor={extraInfoEnabledFor}
              onProductSelected={onProductSelected}
              onExtraInfoSelected={onExtraInfoSelected}
            />
          </View>
          <View style={styles.sectionContainer}>
            <BannerImage
              source={howItWorks}
              title={formatMessage(messages.introActionTitle)}
              description={formatMessage(messages.introActionDescription)}
              button={{
                title: formatMessage(messages.introActionButtonTitle),
                variant: "secondary",
                onPress: onIntroActionPressed,
                IconComponent: SVGs.ArrowRightMono,
                iconPosition: "trailing",
              }}
            />
          </View>
          {(showNoContactIssue || showNoInsuranceIssue) && (
            <View style={styles.sectionContainer}>
              <Heading spacing="l">
                {formatMessage(messages.addInformationSectionTitle)}
              </Heading>
              {showNoContactIssue && (
                <Card
                  title={formatMessage(messages.noContactTitle)}
                  titleIsBold
                  description={formatMessage(messages.noContactDescription)}
                  icon={
                    <IconContainer size="xs" variant="plain">
                      <SVGs.ContactInfo />
                    </IconContainer>
                  }
                  onPress={handleEditContactInformation}
                />
              )}
              {showNoInsuranceIssue && (
                <Card
                  title={formatMessage(messages.noInsuranceTitle)}
                  titleIsBold
                  description={formatMessage(messages.noInsuranceDescription)}
                  icon={
                    <IconContainer size="xs" variant="plain">
                      <SVGs.Insurance />
                    </IconContainer>
                  }
                  onPress={handleEditAccountPaymentMethods}
                  spacing="none"
                />
              )}
            </View>
          )}
          {account.country.codeIso2 === "DK" && (
            <View style={styles.sectionContainer}>
              <View style={styles.pharmaciesSectionContainer}>
                <EyrButton
                  variant="text"
                  title={formatMessage(messages.apoproPharmacyButtonTitle)}
                  onPress={handleGoToApoproOnlinePharmacy}
                />
                <Paragraph size="s">
                  {formatMessage(messages.apoproPharmacyDescription)}
                </Paragraph>
              </View>
            </View>
          )}
        </ScrollView>
      )}
    </View>
  );
}

HomeTabScreen.routeName = "HomeTabScreen";
HomeTabScreen.navigationOptions = {
  title: messages.navigationTitle,
  tabBarIcon: getTabBarIconFn(SVGs.HomeMono),
};
