import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { SectionList, View } from "react-native";
import {
  capitalize,
  flow,
  groupBy,
  get,
  noop,
  entries,
  sortBy,
  map,
} from "lodash/fp";
import { useIntl } from "react-intl";
import { ProductType } from "@eyr-mobile/domain/Order";
import moment from "moment";
import { formatNextTwoDaysAsRelative_DateOtherwise } from "@eyr-mobile/core/Lib";

import { Card } from "../Card";
import { Paragraph } from "../Paragraph";
import { ResAwareSvg } from "../ResAwareSvg";
import { IconContainer } from "../IconContainer";
import { ProfileImage } from "../ProfileImage";

import { styles } from "./Schedule.styles";
import { messages } from "./Schedule.messages";

export function Schedule({
  scheduleItems,
  onScheduleItemSelected,
  showProviderName,
  showSectionTitles,
  productType,
  productImage,
  ...rest
}) {
  const intl = useIntl();
  const { formatTime, formatMessage } = intl;
  const renderItem = useCallback(
    ({ item: category }) => {
      const { startsAt, provider } = category;
      return (
        <Card
          icon={
            <ProfileImage
              uri={provider.image}
              placeholder={provider.name}
              size="s"
            />
          }
          title={formatTime(startsAt)}
          titleIsBold
          description={showProviderName && get("name", provider)}
          onPress={() => onScheduleItemSelected(category)}
        />
      );
    },
    [formatTime, onScheduleItemSelected, showProviderName]
  );

  const keyExtractor = useCallback((item) => item.uuid, []);

  const sections = useMemo(
    () =>
      flow([
        groupBy(({ startsAt }) => moment.utc(startsAt).format("YYYYMMDD")),
        entries,
        sortBy(([date]) => date),
        map(([key, data]) => ({
          key,
          title: formatNextTwoDaysAsRelative_DateOtherwise({
            date: data[0].startsAt,
            intl,
          }),
          data: sortBy("startsAt", data),
        })),
      ])(scheduleItems),
    [scheduleItems, intl]
  );

  const handleRenderSectionHeader = useCallback(
    ({ section: { title } }) =>
      showSectionTitles && (
        <View style={styles.sectionHeaderContainer}>
          <Paragraph size="l">{capitalize(title)}</Paragraph>
        </View>
      ),
    [showSectionTitles]
  );

  return (
    <SectionList
      sections={sections}
      keyExtractor={keyExtractor}
      renderSectionHeader={handleRenderSectionHeader}
      renderItem={renderItem}
      stickySectionHeadersEnabled={false}
      ListEmptyComponent={
        <View style={styles.noScheduleItemsContainer}>
          {productImage && (
            <View style={styles.iconContainer}>
              <IconContainer variant="plain" size="l">
                <ResAwareSvg svg={productImage} />
              </IconContainer>
            </View>
          )}
          <Paragraph size="l" alignment="center">
            {productType === ProductType.PSYCHOLOGIST_CONSULTATION
              ? formatMessage(messages.noAvailablePsychologistAppointments)
              : formatMessage(messages.noAvailableAppointments)}
          </Paragraph>
        </View>
      }
      {...rest}
    />
  );
}

Schedule.propTypes = {
  scheduleItems: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      startsAt: PropTypes.string.isRequired,
      provider: PropTypes.shape({
        name: PropTypes.string.isRequired,
      }).isRequired,
    })
  ),
  showProviderName: PropTypes.bool,
  onScheduleItemSelected: PropTypes.func,
  productType: PropTypes.oneOf([
    "CONSULTATION",
    "PSYCHOLOGIST_CONSULTATION",
    "GUIDE_CONSULTATION",
  ]),
  productImage: PropTypes.string,
  showSectionTitles: PropTypes.bool,
};

Schedule.defaultProps = {
  showProviderName: true,
  scheduleItems: [],
  onScheduleItemSelected: noop,
  showSectionTitles: true,
};
