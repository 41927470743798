import React, { useCallback, useEffect, useState } from "react";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { useNavigation, useRoute } from "@react-navigation/native";
import { WebView } from "react-native-webview";
import {
  applicationNameForUserAgent,
  disableScalingInjectedJavaScript,
  patchPostMessageInjectedJavaScript,
  safeHandlePostMessage,
} from "@eyr-mobile/core/WebView";
import { useAuth } from "@eyr-mobile/domain/Auth";
import { LoggerFactory } from "@eyr-mobile/core/Logger";
import { getWebViewURL } from "@eyr-mobile/core/Net";
import { Platform } from "react-native";
import { maybeOpenURL } from "@eyr-mobile/core/Linking";
import { setErrorTrackingAttributes } from "@eyr-mobile/core/ErrorTracking";
import { setAnalyticsUserProperties } from "@eyr-mobile/core/Analytics";

import { Loading } from "../../components";

import { styles } from "./IdentificationScreen.styles";
import { messages } from "./IdentificationScreen.messages";

const logger = LoggerFactory.get("screens/IdentificationScreen");

const injectedJavaScript =
  disableScalingInjectedJavaScript + patchPostMessageInjectedJavaScript;

const regex = /^jwt:/;
const HARDCODED_IDENTITY_PROVIDERS_NOT_COMPATIBLE_WITH_IFRAME = [
  "signicat_bankid",
  "signicat_bankid_for_mobile",
  "signicat_oidc_mitid",
  "signicat_oidc_se_bankid",
  "signicat_oidc_se_bankid_qr",
  "signicat_oidc_se_bankid_local",
];

export function IdentificationScreen() {
  const { completeIdentification, completeReidentification } = useAuth();
  const route = useRoute();
  const [key, setKey] = useState(1);
  const { navigate, popToTop } = useNavigation();

  const routeParams = route.params || {};
  const {
    identityProviderId,
    reidentification,
    returnRoute,
    accessToken: accessTokenParam,
  } = routeParams;
  const uri = identityProviderId
    ? `${getWebViewURL()}/identity-provider/${identityProviderId}`
    : `${getWebViewURL()}/identity-providers`;
  const compatibleWithIframe =
    !HARDCODED_IDENTITY_PROVIDERS_NOT_COMPATIBLE_WITH_IFRAME.includes(
      identityProviderId
    );
  const isWeb = Platform.OS === "web";

  const onMessage = useCallback(
    ({ nativeEvent }) => {
      safeHandlePostMessage(nativeEvent, logger, async (data) => {
        const [result] = data.split(":");
        switch (result) {
          case "jwt":
            if (!data.match(regex)) {
              return;
            }
            const token = nativeEvent.data.replace(regex, "");
            if (!token) {
              return;
            }
            if (reidentification) {
              completeReidentification(token);
              if (returnRoute) {
                navigate(returnRoute);
              } else {
                popToTop();
              }
            } else {
              completeIdentification(token);
            }
            setKey(key + 1);
            break;
          case "error":
            popToTop();
            break;
          default:
            break;
        }
      });
    },
    [
      completeIdentification,
      key,
      navigate,
      popToTop,
      reidentification,
      completeReidentification,
      returnRoute,
    ]
  );

  const onShouldStartLoadWithRequest = useCallback((event) => {
    const url = event.url;
    if (Platform.OS !== "android" || (url && url.startsWith("http"))) {
      return true;
    }
    maybeOpenURL(url);
    return false;
  }, []);

  const onError = useCallback((error) => {
    logger("WebView error: ", error);
  }, []);

  /**
   Safari doesn't allow us to set third-party cookies in iframes
   resulting in some identity providers failing their identification logic on web.

   Following code is a workaround for that.

   Example for local development environment:
   We redirect customer to identity provider route with callbackURL like
   https://api.dev.eyr.md/webview/identity-provider/signicat_bankid?callbackURL=http%3A%2F%2Flocalhost%3A8080%2FUnauthenticatedIdentificationScreen
   when they are done with identification we redirect them back to
   http://localhost:8080/UnauthenticatedIdentificationScreen?accessToken=token
   */
  useEffect(() => {
    if (!isWeb || compatibleWithIframe || accessTokenParam) {
      return;
    }
    // if (!/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) return;
    const url = new URL(uri);
    const callbackURL = new URL(window.location.origin);
    callbackURL.pathname = "/identification";
    const searchParams = new URLSearchParams(url.searchParams);
    searchParams.append("callbackURL", callbackURL.toString());
    url.search = searchParams.toString();
    window.location.replace(url);
  }, [
    accessTokenParam,
    identityProviderId,
    compatibleWithIframe,
    isWeb,
    uri,
    route.name,
  ]);

  useEffect(() => {
    if (!accessTokenParam) {
      return;
    }
    onMessage({ nativeEvent: { data: `jwt:${accessTokenParam}` } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setErrorTrackingAttributes({ identityProviderId });
    setAnalyticsUserProperties({ identityProviderId });
  }, [identityProviderId]);

  if (isWeb && (!compatibleWithIframe || accessTokenParam)) {
    return <Loading />;
  }

  return (
    <SafeAreaView style={styles.container} edges={["left", "right", "bottom"]}>
      <WebView
        key={key.toString()}
        source={{
          uri,
        }}
        onShouldStartLoadWithRequest={onShouldStartLoadWithRequest}
        decelerationRate="normal"
        automaticallyAdjustContentInsets={false}
        applicationNameForUserAgent={applicationNameForUserAgent}
        bounces={false}
        injectedJavaScript={injectedJavaScript}
        scalesPageToFit={false}
        startInLoadingState
        onMessage={onMessage}
        onError={onError}
        originWhitelist={["*"]}
        cacheEnabled={false}
        web_postMessageTarget={"window"}
        testID={"IdentityProvider"}
      />
    </SafeAreaView>
  );
}

IdentificationScreen.routeName = "IdentificationScreen";
IdentificationScreen.navigationOptions = {
  title: messages.navigationTitle,
};
