import React, { useCallback, useEffect, useState } from "react";
import { ScrollView, View } from "react-native";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { useIntl } from "@eyr-mobile/core/Intl";
import { find, get } from "lodash/fp";
import {
  configurePhoneNumberValidator,
  emailValidator as coreEmailValidator,
  validateIfNotBlank,
} from "@eyr-mobile/core/Validation";
import {
  useMutation,
  useQuery,
  withHandlers,
} from "@eyr-mobile/core/DataProvider";
import { useDevice } from "@eyr-mobile/core/Device";
import { useForm } from "@eyr-mobile/core/Form";
import { usePrevious } from "@eyr-mobile/core/Lib";
import {
  GetOnboardingAddAccountContactInfoData,
  AddOnboardingAccountContactInfo,
  useOnboarding,
} from "@eyr-mobile/domain/Onboarding";

import { ContactInformationForm, EyrButton } from "../../components";

import { messages } from "./OnboardingAddAccountContactInfoScreen.messages";
import { styles } from "./OnboardingAddAccountContactInfoScreen.styles";

const nullifiedPhone = {
  phoneNumber: null,
  phoneCountryCode: null,
};
const getPhoneNumberLengthForCountryPhoneCode = (codePhone, countries = []) => {
  return get("phoneNumberLength", find({ codePhone }, countries));
};
const emailValidator = validateIfNotBlank(coreEmailValidator);
export function OnboardingAddAccountContactInfoScreen() {
  const { handlers, data: { account, countries } = {} } = withHandlers(
    useQuery(GetOnboardingAddAccountContactInfoData)
  );

  const { completeOnboardingStage, skipOnboardingStage } = useOnboarding();

  const { formatMessage } = useIntl();
  const [updateAccount, { loading }] = useMutation(
    AddOnboardingAccountContactInfo
  );

  const { screenSizeSelect } = useDevice();

  const handlePrimaryAction = useCallback(
    async (fields) => {
      await updateAccount({
        variables: {
          input: {
            ...fields,
            email: fields.email || null,
            ...((!fields.phoneNumber || !fields.phoneCountryCode) &&
              nullifiedPhone),
          },
        },
      });
      completeOnboardingStage("OnboardingAddAccountContactInfoScreen");
    },
    [completeOnboardingStage, updateAccount]
  );
  const handleSecondaryAction = useCallback(
    () => skipOnboardingStage("OnboardingAddAccountContactInfoScreen"),
    [skipOnboardingStage]
  );

  const [validators, setValidators] = useState();
  const { form, fields, updateInitialValues } = useForm(
    {
      initialValues: {
        phoneCountryCode: "",
        phoneNumber: "",
        email: "",
        notificationSettings: 0,
      },
      validators,
      onSubmit: handlePrimaryAction,
    },
    {
      validateOnChange: true,
      validateOnBlur: true,
      validateOnMount: true,
    }
  );

  useEffect(() => {
    if (!account) {
      return;
    }
    updateInitialValues({
      phoneCountryCode: account.country.codePhone,
    });
  }, [account, updateInitialValues]);

  const previousPhoneCountryCode = usePrevious(fields.phoneCountryCode.value);
  useEffect(() => {
    const phoneCountryCode = fields.phoneCountryCode.value;
    if (phoneCountryCode !== previousPhoneCountryCode) {
      setValidators({
        email: emailValidator,
        phoneNumber: validateIfNotBlank(
          configurePhoneNumberValidator(
            getPhoneNumberLengthForCountryPhoneCode(phoneCountryCode, countries)
          )
        ),
      });
    }
  }, [countries, fields.phoneCountryCode.value, previousPhoneCountryCode]);
  const previousValidators = usePrevious(validators);
  const validateForm = form.validate;
  useEffect(() => {
    if (validators !== previousValidators) {
      validateForm();
    }
  }, [validateForm, validators, previousValidators]);

  return (
    <SafeAreaView style={styles.container} edges={["left", "right", "bottom"]}>
      {handlers || (
        <>
          <ScrollView
            style={styles.contentContainer}
            contentContainerStyle={screenSizeSelect({
              xs: styles.contentContainerXS,
              s: styles.contentContainerS,
              m: styles.contentContainerM,
            })}
          >
            <ContactInformationForm {...fields} countries={countries} />
          </ScrollView>
          <View style={styles.footerContainer}>
            <View
              style={screenSizeSelect({
                xs: styles.footerContentContainerXS,
                s: styles.footerContentContainerS,
                m: styles.footerContentContainerM,
              })}
            >
              {(fields.phoneNumber.dirty || fields.email.dirty) &&
                form.valid && (
                  <View
                    style={[
                      styles.actionContainer,
                      screenSizeSelect({
                        xs: styles.primaryActionContainerXS,
                        s: styles.primaryActionContainerS,
                      }),
                    ]}
                  >
                    <EyrButton
                      variant="primary"
                      title={formatMessage(messages.primaryActionTitle)}
                      disabled={loading}
                      size="l"
                      onPress={form.submit}
                    />
                  </View>
                )}
              <View style={styles.actionContainer}>
                <EyrButton
                  variant="text"
                  title={formatMessage(messages.secondaryActionTitle)}
                  size="l"
                  onPress={handleSecondaryAction}
                />
              </View>
            </View>
          </View>
        </>
      )}
    </SafeAreaView>
  );
}

OnboardingAddAccountContactInfoScreen.routeName =
  "OnboardingAddAccountContactInfoScreen";
OnboardingAddAccountContactInfoScreen.navigationOptions = {
  title: messages.navigationTitle,
};
