import React, { useCallback } from "react";
import { View, ScrollView } from "react-native";
import { getOr } from "lodash/fp";
import { useQuery, withHandlers } from "@eyr-mobile/core/DataProvider";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { useIntl } from "@eyr-mobile/core/Intl";
import { useDevice } from "@eyr-mobile/core/Device";
import {
  useOnboarding,
  OnboardingSelectInsuranceOrDiscountScreenData,
} from "@eyr-mobile/domain/Onboarding";
import { useWhiteLabel } from "@eyr-mobile/domain/WhiteLabel";

import { EyrButton, SelectInsuranceOrDiscount } from "../../components";

import { styles } from "./OnboardingSelectInsuranceOrDiscountScreen.styles";
import { messages } from "./OnboardingSelectInsuranceOrDiscountScreen.messages";

const getAccountCountryInsuranceProviders = getOr([], "account.country.orgs");
export function OnboardingSelectInsuranceOrDiscountScreen() {
  const whitelabel = useWhiteLabel();
  const { handlers, data } = withHandlers(
    useQuery(OnboardingSelectInsuranceOrDiscountScreenData, {
      variables: { whitelabel },
    })
  );

  const { formatMessage } = useIntl();
  const { screenSizeSelect } = useDevice();
  const { completeOnboardingStage, skipOnboardingStage } = useOnboarding();

  const handleCompleteOnboardingStage = useCallback(
    ({ slug }) =>
      completeOnboardingStage("OnboardingSelectInsuranceOrDiscountScreen", {
        slug,
      }),
    [completeOnboardingStage]
  );

  const handleSecondaryAction = useCallback(
    () => skipOnboardingStage("OnboardingSelectInsuranceOrDiscountScreen"),
    [skipOnboardingStage]
  );

  const insuranceProviders = getAccountCountryInsuranceProviders(data);

  return (
    <SafeAreaView style={styles.container} edges={["left", "right", "bottom"]}>
      {handlers || (
        <>
          <ScrollView
            style={styles.contentContainer}
            contentContainerStyle={screenSizeSelect({
              xs: styles.contentContainerXS,
              s: styles.contentContainerS,
              m: styles.contentContainerM,
            })}
          >
            <SelectInsuranceOrDiscount
              insuranceProviders={insuranceProviders}
              onInsuranceOrDiscountPress={handleCompleteOnboardingStage}
            />
          </ScrollView>
          <View style={styles.footerContainer}>
            <View
              style={screenSizeSelect({
                xs: styles.footerContentContainerXS,
                s: styles.footerContentContainerS,
                m: styles.footerContentContainerM,
              })}
            >
              <EyrButton
                variant="text"
                title={formatMessage(messages.secondaryActionTitle)}
                size="l"
                onPress={handleSecondaryAction}
              />
            </View>
          </View>
        </>
      )}
    </SafeAreaView>
  );
}

OnboardingSelectInsuranceOrDiscountScreen.routeName =
  "OnboardingSelectInsuranceOrDiscountScreen";
OnboardingSelectInsuranceOrDiscountScreen.navigationOptions = {
  title: messages.navigationTitle,
};
