import { gql } from "@apollo/client";

import { getAPI_URL } from "../../core/Net";
import { getAuthorizationBearerHeader } from "../Auth";

export const productFields = gql`
  fragment productFields on Product {
    id
    name
    price {
      amount
      currency
    }
    availabilityState
    description
    type
    subtype
    image
    coverImageUrl
    coverages {
      org {
        id
        smallIconUrl
      }
      discount {
        amount
        currency
      }
      type
    }
  }
`;

export const serviceFields = gql`
  fragment serviceFields on Service {
    __typename
    id
    forMe
    order {
      id
      insertedAt
      product {
        id
        type
      }
    }
    summary
    provider {
      id
      name
      description
      image
    }
    notice
    state
    ... on DropInConsultationService {
      queuePosition
      queueWaitingTimeMin
    }
    ... on AppointmentConsultationService {
      date
      digitalTool {
        __typename
        id
        url
      }
    }
    ... on PatientGuideService {
      date
      treatmentPlanId
      attachedDocumentsCount
      serviceState
    }
  }
`;

export const treatmentPlanFields = gql`
  fragment treatmentPlanFields on TreatmentPlan {
    __typename
    id
    name
    documents {
      __typename
      createdAt
      extension
      id
      title
      downloadUrl
    }
    provider {
      __typename
      description
      id
      image
      name
    }
  }
`;

export const GetProducts = gql`
  query GetProducts($type: ProductType) {
    products(type: $type) {
      ...productFields
    }
  }
  ${productFields}
`;
export const accountPaymentMethodFields = gql`
  fragment accountPaymentMethodFields on AccountPaymentMethod {
    id
    type
    name
    image
    description
    disabled
    disabledReason
    disabledResolutionUrl
    controlQuestions
  }
`;
export const orderFields = gql`
  fragment orderFields on Order {
    id
    state
    product {
      id
      name
      price {
        amount
        currency
      }
      description
      type
      subtype
      image
    }
    flowStages {
      __typename
      id
      ... on FlowStageAttachForm {
        formUrl
      }
      ... on FlowStageSpecifyAppointmentInformation {
        subStage
        initialSelectedProvider {
          id
          name
        }
      }
    }
    currentStageId
    stages {
      __typename
      orderId
      stageId
      ... on OrderStageSelectClient {
        forMe
        otherPersonUid
      }
      ... on OrderStageAttachForm {
        formDataId
      }
      ... on OrderStageSetPaymentMethods {
        cardId
        providerSlug
        discountIds
      }
      ... on OrderStageSetMedicalCategory {
        medicalCategory {
          id
          image
          name
        }
        freeFormSymptoms
      }
      ... on OrderStageSpecifyAppointmentInformation {
        now
        timeslot {
          id
          startsAt
          provider {
            id
            name
          }
        }
      }
    }
  }
`;
export const paymentProviderFields = gql`
  fragment paymentProviderFields on PaymentProvider {
    slug
    name
    image
  }
`;
export const CancelOrder = gql`
  mutation CancelOrder($input: CancelOrderInput!) {
    cancelOrder(input: $input) {
      order {
        id
        state
      }
    }
  }
`;

// Original: CancelCheckoutForOrder
export const CancelCheckoutForOrder = gql`
  mutation CancelCheckoutForOrder($input: CancelCheckoutOrderInput!) {
    cancelCheckoutForOrder(input: $input) {
      order {
        id
      }
    }
  }
`;
export const ModifyOrderDeleteStageData = gql`
  mutation ModifyOrderDeleteStageData(
    $input: ModifyOrderDeleteStageDataInput!
  ) {
    modifyOrderDeleteStageData(input: $input) {
      order {
        ...orderFields
      }
    }
  }
  ${orderFields}
`;
export const InitOrder = gql`
  mutation InitOrder($input: InitOrderInput!) {
    initOrder(input: $input) {
      order {
        id
        state
      }
    }
  }
`;
export const FinishOrder = gql`
  mutation FinishOrder($input: FinishOrderInput!) {
    finishOrder(input: $input) {
      checkoutUrl
      order {
        id
        state
      }
    }
  }
`;
export const GetQueueWaitingTimeMinutes = gql`
  query GetQueueWaitingTimeMinutes {
    queueWaitingTimeMinutes
  }
`;
export const ModifyOrderAttachForm = gql`
  mutation ModifyOrderAttachForm($input: ModifyOrderAttachFormInput!) {
    modifyOrderAttachForm(input: $input) {
      order {
        ...orderFields
      }
    }
  }
  ${orderFields}
`;
export const ModifyOrderSelectClient = gql`
  mutation ModifyOrderSelectClient($input: ModifyOrderSelectClientInput!) {
    modifyOrderSelectClient(input: $input) {
      order {
        ...orderFields
      }
    }
  }
  ${orderFields}
`;
export const ModifyOrderSetMedicalCategory = gql`
  mutation ModifyOrderSetMedicalCategory(
    $input: ModifyOrderSetMedicalCategoryInput!
  ) {
    modifyOrderSetMedicalCategory(input: $input) {
      order {
        ...orderFields
      }
    }
  }
  ${orderFields}
`;
export const ModifyOrderSpecifyAppointmentInformation = gql`
  mutation ModifyOrderSpecifyAppointmentInformation(
    $input: ModifyOrderSpecifyAppointmentInformationInput!
  ) {
    modifyOrderSpecifyAppointmentInformation(input: $input) {
      order {
        ...orderFields
      }
    }
  }
  ${orderFields}
`;
export const ModifyOrderSetPaymentMethods = gql`
  mutation ModifyOrderSetPaymentMethods(
    $input: ModifyOrderSetPaymentMethodsInput!
  ) {
    modifyOrderSetPaymentMethods(input: $input) {
      order {
        ...orderFields
      }
    }
  }
  ${orderFields}
`;
export const AddTokenizedCard = gql`
  mutation AddTokenizedCard($input: AddTokenizedCardInput!) {
    addTokenizedCard(input: $input) {
      card {
        ...accountPaymentMethodFields
      }
    }
  }
  ${accountPaymentMethodFields}
`;
export async function tokenizeCard({ number, exp_month, exp_year, cvc }) {
  const stripeConfigResponse = await fetch(
    `${getAPI_URL()}/config/stripe_public_key`
  );
  const { data: stripeConfig } = await stripeConfigResponse.json();
  const { stripe_public_key } = stripeConfig;
  const body = new URLSearchParams();
  body.append("card[number]", number);
  body.append("card[exp_month]", exp_month);
  body.append("card[exp_year]", exp_year);
  body.append("card[cvc]", cvc);
  const tokenizationResponse = await fetch("https://api.stripe.com/v1/tokens", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      ...getAuthorizationBearerHeader(stripe_public_key),
    },
    body: body.toString(),
  });
  const stripeJSON = await tokenizationResponse.json();
  const { id, error } = stripeJSON;
  if (id) {
    return id;
  }
  if (error) {
    throw error;
  }
}
export const GetAccountChildren = gql`
  query GetAccountChildren {
    account {
      id
      children {
        id
        name
        countryUid
      }
      country {
        id
        consentAge
      }
    }
  }
`;
export const GetPhysiciansTabScreenData = gql`
  query GetPhysiciansTabScreenData {
    serviceProviders {
      id
      name
      image
      description
      treatsMe
      services {
        product {
          id
          type
          subtype
          availabilityState
        }
        state
      }
    }
  }
`;
export const GetHomeTabScreenData = gql`
  query GetHomeTabScreenData {
    products {
      ...productFields
    }
    services {
      ...serviceFields
    }
    account {
      id
      email
      firstName
      lastName
      countryUid
      customerUserId
      phoneNumber
      phoneCountryCode
      country {
        id
        codeIso2
      }
      slug
    }
    accountPaymentMethods {
      id
      type
    }
  }
  ${productFields}
  ${serviceFields}
`;
export const GetHowItWorksScreenData = gql`
  query GetHowItWorksScreenData {
    products {
      id
      type
      availabilityState
    }
    account {
      id
      country {
        id
        codeIso2
      }
    }
  }
`;
export const GetAccountPaymentMethods = gql`
  query GetAccountPaymentMethods {
    accountPaymentMethods {
      id
      type
      name
      image
      description
      disabled
      disabledReason
    }
  }
`;
export const DeleteAccountPaymentMethod = gql`
  mutation DeleteAccountPaymentMethod(
    $input: DeleteAccountPaymentMethodInput!
  ) {
    deleteAccountPaymentMethod(input: $input) {
      accountPaymentMethod {
        id
      }
    }
  }
`;
export const AnswerTravelControlForAccountPaymentMethod = gql`
  mutation AnswerTravelControlForAccountPaymentMethod(
    $input: AnswerTravelControlForAccountPaymentMethodInput!
  ) {
    answerTravelControlForAccountPaymentMethod(input: $input) {
      ...accountPaymentMethodFields
      orderCoverage {
        discount {
          amount
          currency
        }
        type
      }
    }
  }
  ${accountPaymentMethodFields}
`;
export const AnswerWorkControlForAccountPaymentMethod = gql`
  mutation AnswerWorkControlForAccountPaymentMethod(
    $input: AnswerWorkControlForAccountPaymentMethodInput!
  ) {
    answerWorkControlForAccountPaymentMethod(input: $input) {
      ...accountPaymentMethodFields
      orderCoverage {
        discount {
          amount
          currency
        }
        type
      }
    }
  }
  ${accountPaymentMethodFields}
`;
export const AnswerChildControlForAccountPaymentMethod = gql`
  mutation AnswerChildControlForAccountPaymentMethod(
    $input: AnswerChildControlForAccountPaymentMethodInput!
  ) {
    answerChildControlForAccountPaymentMethod(input: $input) {
      ...accountPaymentMethodFields
      orderCoverage {
        discount {
          amount
          currency
        }
        type
      }
    }
  }
  ${accountPaymentMethodFields}
`;
export const GetMedicalCategoriesAndEmergencyNumber = gql`
  query GetMedicalCategoriesAndEmergencyNumber($orderId: ID!) {
    account {
      id
      country {
        id
        emergencyNumber
      }
    }
    medicalCategoriesForOrder(orderId: $orderId) {
      id
      name
      image
      enableFreeFormSymptoms
    }
  }
`;
export const GetAppointmentOptionsAndAccountInfo = gql`
  query GetAppointmentOptionsAndAccountInfo(
    $orderId: Int!
    $filter: AppointmentOptionsFilter
  ) {
    appointmentOptions(orderId: $orderId, filter: $filter) {
      filterValues {
        dates
        providers {
          id
          name
          description
          image
        }
      }
      scheduleItems {
        uuid
        startsAt
        provider {
          id
          name
          description
          image
        }
      }
    }
    account {
      country {
        id
        emergencyNumber
        codeIso2
      }
    }
  }
`;
export const GetOrderById = gql`
  query GetOrderById($id: Int!) {
    order(id: $id) {
      ...orderFields
    }
  }
  ${orderFields}
`;
export const GetPostOrderData = gql`
  query GetPostOrderData {
    account {
      id
      phoneNumber
      phoneCountryCode
    }
  }
`;
export const GetPaymentMethodsForOrder = gql`
  query GetPaymentMethodsForOrder($orderId: Int!) {
    accountPaymentMethodsForOrder(orderId: $orderId) {
      ...accountPaymentMethodFields
      orderCoverage {
        discount {
          amount
          currency
        }
        type
      }
    }
    paymentProvidersForOrder(orderId: $orderId) {
      ...paymentProviderFields
    }
  }
  ${accountPaymentMethodFields}
  ${paymentProviderFields}
`;
export const GetFlowStageSetPaymentMethodsData = gql`
  query GetFlowStageSetPaymentMethodsData($orderId: Int!) {
    accountPaymentMethodsForOrder(orderId: $orderId) {
      ...accountPaymentMethodFields
      orderCoverage {
        type
        discount {
          amount
          currency
        }
      }
    }
    paymentProvidersForOrder(orderId: $orderId) {
      ...paymentProviderFields
    }
    countries(available: true) {
      id
      codePhone
      phoneNumberLength
    }
    account {
      id
      country {
        id
        codePhone
        phoneNumberLength
      }
      phoneCountryCode
      phoneNumber
    }
  }
  ${accountPaymentMethodFields}
  ${paymentProviderFields}
`;
export const DismissOrder = gql`
  mutation DismissOrder($input: DismissOrderInput!) {
    dismissOrder(input: $input) {
      order {
        id
      }
    }
  }
`;
export const Get_WorkingHours_QueueWaitingTime_AppointmentOptions = gql`
  query Get_WorkingHours_WaitingTime_AppointmentOptions(
    $orderId: Int!
    $startDate: Date!
    $finishDate: Date!
  ) {
    workingHours(startDate: $startDate, finishDate: $finishDate) {
      opensAt
      closesAt
    }
    queueWaitingTimeMinutes
    appointmentOptions(orderId: $orderId) {
      scheduleItems {
        uuid
        startsAt
      }
    }
  }
`;
export const AddChild = gql`
  mutation AddChild($input: AddChildInput!) {
    addChild(input: $input) {
      child {
        id
        name
        countryUid
      }
    }
  }
`;
export const GetAccountCountryCodeAndConsentAge = gql`
  query GetAccountCountryCodeAndConsentAge {
    account {
      id
      country {
        id
        codeIso2
        consentAge
      }
    }
  }
`;
export const GetAccountCountryCode = gql`
  query GetAccountCountryCode {
    account {
      id
      country {
        id
        codeIso2
      }
    }
  }
`;

export const ProductsUpdatedSubscription = gql`
  subscription ProductsUpdatedSubscription {
    productsUpdated {
      ...productFields
    }
  }
  ${productFields}
`;

export const ServicesUpdatedSubscription = gql`
  subscription ServicesUpdatedSubscription {
    servicesUpdated {
      ...serviceFields
    }
  }
  ${serviceFields}
`;

export const GetTravelControlCountryData = gql`
  query GetTravelControlCountryData($orderId: Int!) {
    countries {
      id
      name
      codeIso3
      codeIso2
    }
    account {
      country {
        id
        codeIso3
        name
      }
    }
    accountPaymentMethodsForOrder(orderId: $orderId) {
      id
      name
      controlQuestions
    }
  }
`;

export const GetTreatmentPlan = gql`
  query GetTreatmentPlan($id: ID!) {
    treatmentPlan(id: $id) {
      ...treatmentPlanFields
    }
  }
  ${treatmentPlanFields}
`;
