import { StyleSheet, Platform } from "react-native";
import { DefaultTheme } from "@react-navigation/native";
import { isDesktopWeb } from "@eyr-mobile/core/Device";

import { fontMaker } from "../../core/Lib/font";
import { Palette } from "../shared-styles/palette";
import { Shadows } from "../shared-styles/shadow";

export const styles = StyleSheet.create({
  headerTitle: {
    ...fontMaker({
      fontWeight: "600",
    }),
    color: Palette.BURGUNDY_100,
    fontSize: 16,
    lineHeight: 24,
  },
  headerLeftContainerStyle: {
    paddingLeft: 5,
    paddingRight: 16,
  },
  headerRightContainerStyle: {
    paddingLeft: 5,
    paddingRight: 16,
  },
  cardStyle: {
    flex: 1,
    backgroundColor: Palette.WHITE,
  },
  flexView: { flex: 1 },
  // better name?
  framedContentContainer: {
    minHeight: 600,
    width: 480,
    borderRadius: 8,
    overflow: "hidden",
    ...Shadows.m,
  },
  framedContainer: {
    minHeight: 680,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Palette.NUDE_20,
  },
  headerBackImage: Platform.select({
    ios: {
      marginHorizontal: 10,
    },
  }),
  tabBarWeb: {
    height: 74,
    paddingTop: 16,
    paddingBottom: 16,
  },
  headerWithWhiteLabel: {
    height: 88,
  },
});

export const ExtendedNavigationTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: Palette.WHITE,
    card: Palette.WHITE,
    primary: Palette.BURGUNDY_100,
    text: Palette.BURGUNDY_100,
    notification: Palette.RED_100,
  },
};

export const stackScreenOptions = {
  headerTitleAlign: "center",
  headerTintColor: Palette.BURGUNDY_100,
  headerPressColorAndroid: Palette.NUDE_100,
  headerTitleStyle: styles.headerTitle,
  headerLeftContainerStyle: styles.headerLeftContainerStyle,
  headerRightContainerStyle: styles.headerRightContainerStyle,
  cardStyle: styles.cardStyle,
  headerBackTitleVisible: false,
  animationEnabled: true,
  // navigation back button doesn't make a lot of sense on desktop web
  ...(isDesktopWeb() && { headerLeft: null }),
};

export const tabBarOptions = {
  tabBarActiveTintColor: Palette.BURGUNDY_100,
  tabBarInactiveTintColor: Palette.GRAY_100,
  tabBarLabelStyle: {
    ...fontMaker({
      fontWeight: "500",
    }),
    fontSize: 10,
    lineHeight: 12,
  },
  tabBarLabelPosition: "below-icon",
  lazy: true,
  headerShown: false,
};

export const stylingProps = {
  tabBarWebS: {
    width: 480,
  },
  tabBarWebM: {
    width: 540,
  },
};

export const getHorizontalContainerPadding = (screenWidth) => ({
  s: (screenWidth - stylingProps.tabBarWebS.width) / 2,
  m: (screenWidth - stylingProps.tabBarWebM.width) / 2,
});
