import React, { useCallback } from "react";
import { View } from "react-native";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { useRoute } from "@react-navigation/native";
import { useAuth } from "@eyr-mobile/domain/Auth";
import {
  GetOnboardingAddInsuranceOrDiscountData,
  useOnboarding,
} from "@eyr-mobile/domain/Onboarding";
import { useDevice } from "@eyr-mobile/core/Device";
import { useQuery, withHandlers } from "@eyr-mobile/core/DataProvider";

import { AddInsuranceOrDiscount } from "../../components";

import { styles } from "./OnboardingAddInsuranceOrDiscountScreen.styles";
import { messages } from "./OnboardingAddInsuranceOrDiscountScreen.messages";

export function OnboardingAddInsuranceOrDiscountScreen() {
  const route = useRoute();
  const { accessToken } = useAuth();
  const { completeOnboardingStage, org } = useOnboarding();
  const { screenSizeSelect } = useDevice();
  const orgParam = route.params?.slug;
  const slug = orgParam || org;
  const { handlers, data } = withHandlers(
    useQuery(GetOnboardingAddInsuranceOrDiscountData, {
      variables: { slug },
    })
  );

  const handleSucceeded = useCallback(() => {
    completeOnboardingStage("OnboardingAddInsuranceOrDiscountScreen");
  }, [completeOnboardingStage]);

  return (
    <SafeAreaView style={styles.container} edges={["left", "right", "bottom"]}>
      {handlers || (
        <View
          style={[
            styles.contentContainer,
            screenSizeSelect({
              xs: styles.contentContainerXS,
              s: styles.contentContainerS,
              m: styles.contentContainerM,
            }),
          ]}
        >
          <AddInsuranceOrDiscount
            accessToken={accessToken}
            onSucceeded={handleSucceeded}
            specificInsuranceOrDiscountUrl={data.org.newPolicyUrl}
          />
        </View>
      )}
    </SafeAreaView>
  );
}

OnboardingAddInsuranceOrDiscountScreen.routeName =
  "OnboardingAddInsuranceOrDiscountScreen";
OnboardingAddInsuranceOrDiscountScreen.navigationOptions = {
  title: messages.navigationTitle,
};
