import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { map, noop, upperFirst } from "lodash/fp";
import { useIntl } from "react-intl";
import {
  formatNextTwoDaysAsRelative_DateOtherwise,
  uniqLocal,
} from "@eyr-mobile/core/Lib";

import { SVGs } from "../../res/svgs";
import { Card } from "../Card";
import { EyrPickerSelect } from "../EyrPickerSelect";

import { messages } from "./DatePicker.messages";

export function DatePicker({
  dates,
  testOnly_nowOverride,
  onDateSelected,
  initialSelectedDate,
}) {
  const intl = useIntl();
  const { formatMessage } = intl;
  const [selectedDate, setSelectedDate] = useState(initialSelectedDate);
  const handleDateChange = useCallback(
    (newDate) => {
      if (newDate === selectedDate) {
        return;
      }
      setSelectedDate(newDate);
      onDateSelected(newDate);
    },
    [onDateSelected, selectedDate]
  );
  return (
    <EyrPickerSelect
      Icon={() => null}
      placeholder={{
        label: formatMessage(messages.noSelectionTitle),
        value: "",
      }}
      value={selectedDate}
      items={map(
        (date) => ({
          value: date,
          label: upperFirst(
            formatNextTwoDaysAsRelative_DateOtherwise({
              date,
              intl,
              nowOverride: testOnly_nowOverride,
            })
          ),
        }),
        uniqLocal(dates)
      )}
      pickerProps={{
        mode: "dialog",
        accessibilityLabel: "Date picker",
        testID: "Date picker",
      }}
      onValueChange={handleDateChange}
    >
      <Card
        title={
          selectedDate
            ? upperFirst(
                formatNextTwoDaysAsRelative_DateOtherwise({
                  date: selectedDate,
                  intl,
                  nowOverride: testOnly_nowOverride,
                })
              )
            : formatMessage(messages.noSelectionTitle)
        }
        InteractivityIconComponent={SVGs.ChevronDownMono}
      />
    </EyrPickerSelect>
  );
}

DatePicker.propTypes = {
  initialSelectedDate: PropTypes.string,
  testOnly_nowOverride: PropTypes.string,
  dates: PropTypes.arrayOf(PropTypes.string),
  onDateSelected: PropTypes.func,
};

DatePicker.defaultProps = {
  initialSelectedDate: null,
  dates: [],
  onDateSelected: noop,
};
