import React, { useCallback, useEffect } from "react";
import { View } from "react-native";
import { SafeAreaView } from "@eyr-mobile/core/SafeArea";
import { useRoute, useNavigation } from "@react-navigation/native";
import { useAuth } from "@eyr-mobile/domain/Auth";
import { LOGGER_LEVEL_WARN, LoggerFactory } from "@eyr-mobile/core/Logger";
import {
  useApolloClient,
  useQuery,
  withHandlers,
} from "@eyr-mobile/core/DataProvider";
import { GetAccountPaymentMethods } from "@eyr-mobile/domain/Order";
import { useDevice } from "@eyr-mobile/core/Device";
import { GetAddAccountInsuranceOrDiscountData } from "@eyr-mobile/domain/Account";

import { AddInsuranceOrDiscount } from "../../components";

import { styles } from "./AddAccountInsuranceOrDiscountScreen.styles";
import { messages } from "./AddAccountInsuranceOrDiscountScreen.messages";

const logger = LoggerFactory.get("screens/AddAccountInsuranceOrDiscountScreen");

export function AddAccountInsuranceOrDiscountScreen() {
  const { navigate } = useNavigation();
  const route = useRoute();
  const { accessToken } = useAuth();
  const client = useApolloClient();
  const slug = route.params?.slug;
  const { handlers, data } = withHandlers(
    useQuery(GetAddAccountInsuranceOrDiscountData, {
      variables: { slug },
    })
  );

  const { screenSizeSelect } = useDevice();

  useEffect(() => {
    if (!slug) {
      logger("expects to have route.params.slug set", slug, LOGGER_LEVEL_WARN);
    }
  }, [slug]);

  const handleSucceeded = useCallback(async () => {
    try {
      await client.query({
        query: GetAccountPaymentMethods,
        fetchPolicy: "network-only",
      });
    } finally {
      navigate("HomeTabScreen");
    }
    navigate("HomeTabScreen");
  }, [client, navigate]);

  return (
    <SafeAreaView style={styles.container} edges={["left", "right", "bottom"]}>
      {handlers || (
        <View
          style={[
            styles.contentContainer,
            screenSizeSelect({
              xs: styles.contentContainerXS,
              s: styles.contentContainerS,
              m: styles.contentContainerM,
            }),
          ]}
        >
          <AddInsuranceOrDiscount
            accessToken={accessToken}
            onSucceeded={handleSucceeded}
            specificInsuranceOrDiscountUrl={data.org.newPolicyUrl}
          />
        </View>
      )}
    </SafeAreaView>
  );
}

AddAccountInsuranceOrDiscountScreen.routeName =
  "AddAccountInsuranceOrDiscountScreen";
AddAccountInsuranceOrDiscountScreen.navigationOptions = {
  title: messages.navigationTitle,
};
