import { StyleSheet, Platform } from "react-native";
import { fontMaker } from "@eyr-mobile/core/Lib";

import { Palette } from "../../shared-styles/palette";

export const styles = StyleSheet.create({
  viewContainer: {
    borderRadius: 8,
    borderWidth: 1,
    height: 56,
    borderColor: Palette.BURGUNDY_20,
    backgroundColor: Palette.WHITE,
    justifyContent: "center",
  },
  placeholder: {
    color: Palette.BURGUNDY_40,
  },
  iconContainer: {
    right: 16,
    top: 16,
    pointerEvents: "none",
  },
  inputAndroid: {
    height: "100%",
    width: "100%",
    padding: 16,
    ...fontMaker(),
    fontSize: 16,
    color: Palette.BURGUNDY_80,
  },
  inputIOS: {
    height: "100%",
    padding: 16,
    ...fontMaker(),
    fontSize: 16,
    color: Palette.BURGUNDY_80,
  },
  inputWeb: {
    borderWidth: 0,
    padding: 16,
    height: "100%",
    backgroundColor: Palette.TRANSPARENT,
    color: Palette.BURGUNDY_80,
    ...fontMaker(),
    fontSize: 16,
    lineHeight: 22,
    appearance: "none",
  },
});

export const iconStylingProps = {
  fill: Palette.BURGUNDY_100,
  width: 22,
  height: 22,
};

export const pickerStylingProps = {
  dropdownIconColor: Palette.WHITE,
  ...(Platform.OS === "android" && {
    dropdownIconRippleColor: Palette.TRANSPARENT,
  }),
};
