import PropTypes from "prop-types";
import { noop, values } from "lodash/fp";
import { createContext } from "react";

export const stateDefaults = {
  orderId: null,
};

const contextDefaults = {
  ...stateDefaults,
  order: null,
  orderHasPaymentMethods: undefined,
  orderHasEveryStageFilled: undefined,
  refetch: noop,
  setOrderState: noop,
  editOrderStage: noop,
};

export const OrderContext = createContext(contextDefaults);
export const ProductType = {
  CONSULTATION: "CONSULTATION",
  PSYCHOLOGIST_CONSULTATION: "PSYCHOLOGIST_CONSULTATION",
  VACCINATION: "VACCINATION",
  GUIDE_CONSULTATION: "GUIDE_CONSULTATION",
};
export const ProductSubtype = {
  APPOINTMENT: "APPOINTMENT",
  ASSISTED_SELF_HELP: "ASSISTED_SELF_HELP",
  ASSISTED_SELF_HELP_FOLLOWUP: "ASSISTED_SELF_HELP_FOLLOWUP",
  COUPLE_FOLLOWUP_CONSULTATION: "COUPLE_FOLLOWUP_CONSULTATION",
  COUPLE_INITIAL_CONSULTATION: "COUPLE_INITIAL_CONSULTATION",
  FORM_BOOSTRIX_POLIO_VACCINATION: "FORM_BOOSTRIX_POLIO_VACCINATION",
  FORM_FLU_VACCINATION: "FORM_FLU_VACCINATION",
  FORM_HPV_VACCINATION: "FORM_HPV_VACCINATION",
  FORM_MENINGITIS_VACCINATION: "FORM_MENINGITIS_VACCINATION",
  FORM_PNEUMOCOCCAL_VACCINATION: "FORM_PNEUMOCOCCAL_VACCINATION",
  FORM_SHINGRIX_VACCINATION: "FORM_SHINGRIX_VACCINATION",
  FORM_TICK_VACCINATION: "FORM_TICK_VACCINATION",
  FORM_TRAVEL_VACCINATION: "FORM_TRAVEL_VACCINATION",
  GUIDE_FOLLOWUP_CONSULTATION: "GUIDE_FOLLOWUP_CONSULTATION",
  GUIDE_INITIAL_CONSULTATION: "GUIDE_INITIAL_CONSULTATION",
  PSYCHOLOGIST_CONSULTATION: "PSYCHOLOGIST_CONSULTATION",
  PSYCHOLOGIST_FOLOWUP_CONSULTATION: "PSYCHOLOGIST_FOLOWUP_CONSULTATION",
  QUEUE: "QUEUE",
  REGULAR_DOCTOR_CONSULTATION: "REGULAR_DOCTOR_CONSULTATION",
};
export const OrderState = {
  CANCELED: "CANCELED",
  FINISHED: "FINISHED",
  INIT: "INIT",
};
export const ControlReason = {
  TRAVEL: "TRAVEL",
  WORK: "WORK",
  CHILD: "CHILD",
};
export const OrderAnalyticsContentType = {
  PRODUCT_EXTRA_INFO: "product_extra_info",
};
export const OrderAnalyticsEvent = {
  COMPLETED_ORDER: "completed_order",
  INIT_ORDER: "init_order",
  CANCEL_ORDER: "cancel_order",
};
export const CoverageType = {
  FULL: "FULL",
  PARTIAL: "PARTIAL",
  DISCOUNT: "DISCOUNT",
  NONE: "NONE",
};
export const ProductAvailabilityState = {
  UNAVAILABLE: "unavailable",
  AVAILABLE: "available",
  PENDING_USAGE: "pending_usage",
};
export const PaymentMethodType = {
  CARD: "CARD",
  INSURANCE: "INSURANCE",
  DISCOUNT: "DISCOUNT",
  PAYMENT_PROVIDER: "PaymentProvider",
};
export const EYR_SUPPORT_EMAIL = "support@eyr.md";
export const moneyShape = PropTypes.shape({
  amount: PropTypes.number.isRequired,
  currency: PropTypes.oneOf(["NOK", "DKK", "USD", "UAH", "EUR", "SEK"]),
});
export const productShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  type: PropTypes.oneOf(values(ProductType)).isRequired,
  price: moneyShape.isRequired,
  description: PropTypes.string,
});
export const ProviderProductState = {
  OPENED_FOR_BOOKING: "opened_for_booking",
  CLOSED_FOR_BOOKING: "closed_for_booking",
};
export const OrderLinkingActions = {
  INIT_ORDER: "initOrder",
  VIEW_EXTRA_INFO: "readMore",
};
export const defaultCurrencyDisplayRules = {
  style: "currency",
  currencyDisplay: "narrowSymbol",
  minimumFractionDigits: 0,
};
