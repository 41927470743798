import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Platform } from "react-native";
import {
  getActionFromState,
  getPathFromState,
  getStateFromPath,
} from "@react-navigation/native";
import { get, noop } from "lodash/fp";
import { addEventListener, createURL } from "expo-linking";

import { LoggerFactory } from "../Logger";
import { addNavigationListener, dispatchNavigationAction } from "../Navigation";
import { Constants } from "../Config";
import { onNotificationOpenedApp } from "../Notifications";

import {
  checkLinkResult,
  ensureHandlableLink,
  getURLFromNotification,
  getLinkingURL,
} from "./Linking.helpers";

const logger = LoggerFactory.get("core/Linking");

const stateDefaults = {
  enabled: true,
  initialURL: null,
  pendingLink: null,
  prefixes: Platform.select({
    default: () => [getLinkingURL().toString()],
    ios: () => [
      createURL("/", { scheme: Constants.expoConfig.scheme }),
      createURL("/", { scheme: Constants.expoConfig.ios.bundleIdentifier }),
      getLinkingURL().toString(),
    ],
    android: () => [
      createURL("/", { scheme: Constants.expoConfig.scheme }),
      createURL("/", { scheme: Constants.expoConfig.android.package }),
      getLinkingURL().toString(),
    ],
  })(),
  getInitialURL: noop,
  subscribe: noop,
  getLinkingURLFromRoute: noop,
};

export const LinkingContext = createContext(stateDefaults);

export function LinkingProvider({ children, initialState, navigationReady }) {
  const initialStateWithDefaults = {
    ...stateDefaults,
    ...initialState,
  };
  const [linkingState, setLinkingState] = useState(initialStateWithDefaults);
  const [currentNavigationPath, setCurrentNavigationPath] = useState("");

  const { initialURL, config, pendingLink } = linkingState;

  const tryToFulfillLink = useCallback(() => {
    if (!pendingLink) {
      return;
    }
    const pendingLinkState = getStateFromPath(pendingLink, config);
    const navigationActionToTry = getActionFromState(pendingLinkState, config);
    if (!navigationActionToTry) {
      return;
    }
    try {
      dispatchNavigationAction(navigationActionToTry);
    } catch {}
  }, [config, pendingLink]);

  const getInitialURL = useCallback(() => initialURL, [initialURL]);
  const getLinkingURLFromRoute = useCallback(
    (route) => {
      const linkingURL = getLinkingURL();
      linkingURL.pathname = getPathFromState({ routes: [route] }, config);
      return linkingURL;
    },
    [config]
  );

  const subscribe = useCallback((listener) => {
    const unsubscribeLinking = addEventListener("url", ({ url }) =>
      listener(url)
    ).remove;

    const unsubscribeMessaging = onNotificationOpenedApp((notification) => {
      const url = getURLFromNotification(notification);
      if (url) {
        listener(url);
      }
    });

    return () => {
      unsubscribeLinking();
      unsubscribeMessaging();
    };
  }, []);

  useEffect(() => {
    if (!initialURL || !navigationReady) {
      return;
    }

    const initialLink = ensureHandlableLink(initialURL);
    const { didDeepLinkLand, isKnownRoute, linkPath } = checkLinkResult(
      initialLink,
      config
    );
    logger("initialLink", { didDeepLinkLand, isKnownRoute, linkPath });
    if (!isKnownRoute || didDeepLinkLand) {
      return;
    }
    setLinkingState((linking) => ({ ...linking, pendingLink: linkPath }));
  }, [config, initialURL, navigationReady]);

  useEffect(() => {
    if (!navigationReady) {
      return;
    }
    return addNavigationListener("state", (event) => {
      const state = get("data.state", event);
      if (!state) {
        return;
      }
      const nextNavigationPath = getPathFromState(state, config);
      if (pendingLink && nextNavigationPath === pendingLink) {
        logger("pendingLink:fulfilled", pendingLink);
        setLinkingState((linking) => ({
          ...linking,
          pendingLink: stateDefaults.pendingLink,
        }));
      }
      setCurrentNavigationPath(nextNavigationPath);
    });
  }, [config, navigationReady, pendingLink]);

  useEffect(() => {
    if (!navigationReady || !pendingLink || !currentNavigationPath) {
      return;
    }
    tryToFulfillLink();
  }, [currentNavigationPath, navigationReady, pendingLink, tryToFulfillLink]);

  useEffect(() => {
    if (Platform.OS === "web") {
      return;
    }
    return addEventListener("url", ({ url: incomingURL }) => {
      const incomingLink = ensureHandlableLink(incomingURL);
      if (!incomingLink) {
        return;
      }
      const { isKnownRoute, didDeepLinkLand, linkPath } = checkLinkResult(
        incomingLink,
        config
      );
      logger("incomingLink", { isKnownRoute, didDeepLinkLand, linkPath });
      if (!isKnownRoute) {
        logger("dropping unknown URL", linkPath);
        return;
      }
      if (!didDeepLinkLand) {
        setLinkingState((linking) => ({ ...linking, pendingLink: linkPath }));
      }
    }).remove;
  }, [config]);

  useEffect(() => {
    logger("pendingLink", pendingLink);
  }, [pendingLink]);

  return (
    <LinkingContext.Provider
      value={{
        ...linkingState,
        getInitialURL,
        subscribe,
        getLinkingURLFromRoute,
      }}
    >
      {children}
    </LinkingContext.Provider>
  );
}

export const useLinking = () => useContext(LinkingContext);
