import { defineMessages } from "react-intl";
import { ProductType } from "@eyr-mobile/domain/Order";

export const messages = defineMessages({
  noProducts: {
    id: "ProductsCatalog.noProducts",
    defaultMessage: "No products",
    description: "Catalog has no products to display",
  },
  primaryActionTitle: {
    id: "ProductsCatalog.primaryActionTitle",
    description: "Primary Action Title",
    defaultMessage: "Book session",
  },
  extraInfoActionTitle: {
    id: "ProductsCatalog.extraInfoActionTitle",
    defaultMessage: "Read more",
    description: "Extra info action title",
  },
  vaccineGroupTitle: {
    id: "ProductsCatalog.vaccineGroupTitle",
    defaultMessage: "Vaccines",
    description: "Vaccine catalog title",
  },
  vaccineGroupDescription: {
    id: "ProductsCatalog.vaccineGroupDescription",
    defaultMessage: "Prescription for vaccines administered in pharmacy",
    description: "Vaccine catalog description",
  },
  vaccinesPrimaryActionTitle: {
    id: "ProductsCatalog.vaccinesPrimaryActionTitle",
    defaultMessage: "Order vaccine",
    description: "Vaccines product primary action title",
  },
  [`${ProductType.PSYCHOLOGIST_CONSULTATION}_ProvidersName`]: {
    id: "ProductsCatalog.PSYCHOLOGIST_CONSULTATION_ProvidersName",
    description: "How we call service providers who do psychology service",
    defaultMessage: "Psychologist",
  },
  [`${ProductType.CONSULTATION}_ProvidersName`]: {
    id: "ProductsCatalog.CONSULTATION_ProvidersName",
    description: "How we call service providers who do therapy service",
    defaultMessage: "Doctor",
  },
  default_ProvidersName: {
    id: "ProductsCatalog.default_ProvidersName",
    description: "Default providers name",
    defaultMessage: "Physician",
  },
});
