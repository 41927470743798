import React, {
  useState,
  createContext,
  useContext,
  useCallback,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import {
  compact,
  indexOf,
  nth,
  size,
  flatten,
  map,
  isArray,
  first,
  noop,
  pick,
} from "lodash/fp";
import { LOGGER_LEVEL_ERROR, LoggerFactory } from "@eyr-mobile/core/Logger";
import { useApolloClient } from "@eyr-mobile/core/DataProvider";
import { useNavigation } from "@react-navigation/native";
import { usePrevious } from "@eyr-mobile/core/Lib";
import { parse } from "@eyr-mobile/core/Linking";

import { CheckOnboardingStatus, CompleteOnboarding } from "./Onboarding.data";

const logger = LoggerFactory.get("domain/Onboarding");

const stateDefaults = {
  org: null,
  onboardingActive: false,
  onboardingFlowStages: [],
  onboardingFlowSkippableStages: [],
};

export const OnboardingContext = createContext({
  ...stateDefaults,
  activateOnboardingFlowIfNeeded: noop,
  completeOnboardingStage: noop,
  skipOnboardingStage: noop,
});

const whitelistedLinkingQueryParams = ["org"];
export function parseInitialOnboardingStateFromLinking(url) {
  if (!url) {
    return;
  }
  const parsedURL = parse(url);
  if (parsedURL.path !== "onboarding") {
    return;
  }
  return pick(whitelistedLinkingQueryParams, parsedURL.queryParams);
}

function shouldAddAccountContactInfo(account = {}) {
  return !account.email && !account.phoneNumber;
}
function shouldAddInsuranceOrDiscount(accountPaymentMethods) {
  return size(accountPaymentMethods) === 0;
}
export function OnboardingProvider({ children, initialState }) {
  const initialStateWithDefaults = {
    ...stateDefaults,
    ...initialState,
  };
  const dataProvider = useApolloClient();
  const [onboardingState, setOnboardingState] = useState(
    initialStateWithDefaults
  );
  const {
    org,
    onboardingActive,
    onboardingFlowStages,
    onboardingFlowSkippableStages,
  } = onboardingState;

  const { navigate } = useNavigation();

  const activateOnboardingFlowIfNeeded = useCallback(async () => {
    const { data: { account, accountPaymentMethods } = {} } =
      await dataProvider.query({
        query: CheckOnboardingStatus,
      });
    if (account.onboardedAt) {
      return;
    }
    const onboardingFlowGroupedStages = compact([
      shouldAddAccountContactInfo(account) &&
        "OnboardingAddAccountContactInfoScreen",
      shouldAddInsuranceOrDiscount(accountPaymentMethods) &&
        compact([
          !org && "OnboardingSelectInsuranceOrDiscountScreen",
          "OnboardingAddInsuranceOrDiscountScreen",
        ]),
    ]);
    const onboardingFlowStages = flatten(onboardingFlowGroupedStages);
    const onboardingFlowSkippableStages = map(
      (stageOrGroup) =>
        isArray(stageOrGroup) ? first(stageOrGroup) : stageOrGroup,
      onboardingFlowGroupedStages
    );
    const shouldActivateOnboardingFlow = size(onboardingFlowStages) > 0;
    setOnboardingState((onboardingState) => ({
      ...onboardingState,
      ...(shouldActivateOnboardingFlow && {
        onboardingActive: shouldActivateOnboardingFlow,
        onboardingFlowStages,
        onboardingFlowSkippableStages,
      }),
    }));
  }, [dataProvider, org]);

  const completeOnboardingStage = useCallback(
    async (stageName, payload) => {
      if (!onboardingActive) {
        return;
      }
      const currentStageIndex = indexOf(stageName, onboardingFlowStages);
      const nextStage = nth(currentStageIndex + 1, onboardingFlowStages);
      if (nextStage) {
        navigate(nextStage, payload);
      } else {
        await dataProvider.mutate({
          mutation: CompleteOnboarding,
        });
        setOnboardingState(stateDefaults);
      }
    },
    [dataProvider, navigate, onboardingActive, onboardingFlowStages]
  );

  const skipOnboardingStage = useCallback(
    async (stageName) => {
      if (!onboardingActive) {
        return;
      }
      const currentStageIndex = indexOf(
        stageName,
        onboardingFlowSkippableStages
      );
      const nextStage = nth(
        currentStageIndex + 1,
        onboardingFlowSkippableStages
      );
      if (nextStage) {
        navigate(nextStage);
      } else {
        await dataProvider.mutate({
          mutation: CompleteOnboarding,
        });
        setOnboardingState(stateDefaults);
      }
    },
    [dataProvider, navigate, onboardingActive, onboardingFlowSkippableStages]
  );
  const previousOnboardingActive = usePrevious(onboardingActive);
  useEffect(() => {
    if (previousOnboardingActive && !onboardingActive) {
    }
  }, [onboardingActive, previousOnboardingActive]);

  return (
    <OnboardingContext.Provider
      value={{
        ...onboardingState,
        activateOnboardingFlowIfNeeded,
        completeOnboardingStage,
        skipOnboardingStage,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
}

OnboardingProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialState: PropTypes.shape({
    org: PropTypes.string,
  }),
};

export function useOnboarding() {
  const onboarding = useContext(OnboardingContext);
  if (onboarding === undefined) {
    logger(
      "useOnboarding: Couldn't find a onboarding object. Is your component inside a OnboardingProvider?",
      onboarding,
      LOGGER_LEVEL_ERROR
    );
    return;
  }
  return onboarding;
}
