import { Platform } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
  useRef,
  useMemo,
} from "react";
import {
  find,
  flow,
  get,
  head,
  isFunction,
  values,
  defaultsDeep,
  has,
  size,
  intersection,
  includes,
  ary,
} from "lodash/fp";
import { logAnalyticsEvent } from "@eyr-mobile/core/Analytics";
import { LOGGER_LEVEL_ERROR, LoggerFactory } from "@eyr-mobile/core/Logger";
import {
  useQuery,
  useApolloClient,
  useMutation,
} from "@eyr-mobile/core/DataProvider";
import { parse, useLinking } from "@eyr-mobile/core/Linking";
import {
  getCurrentNavigationRoute,
  push,
  replace,
  navigate,
  addNavigationListener,
  goBack,
} from "@eyr-mobile/core/Navigation";
import {
  recordError,
  setErrorTrackingAttributes,
} from "@eyr-mobile/core/ErrorTracking";
import { usePrevious } from "@eyr-mobile/core/Lib";
import { show as showToast } from "@eyr-mobile/core/Toast";
import { getAPI_URL } from "@eyr-mobile/core/Net";
import * as WebBrowser from "@eyr-mobile/core/WebBrowser";
import { useAppState } from "@eyr-mobile/core/AppState";
import { useAlert } from "@eyr-mobile/core/Alert";
import { safeHandlePostMessage } from "@eyr-mobile/core/WebView";

import { AuthState, useAuth } from "../Auth";

import {
  controlQuestionToRoute,
  doesCover,
  getApplicablePaymentMethodByIds,
  getPassPropsFromOrder,
  hasEveryStageFilled,
  hasPaymentMethods,
  isKnownFlowStage,
  isStageCompleted,
  orderStageToFlowStage,
  stageTypeToRoute,
} from "./Order.helpers";
import {
  AnswerChildControlForAccountPaymentMethod,
  AnswerTravelControlForAccountPaymentMethod,
  AnswerWorkControlForAccountPaymentMethod,
  CancelCheckoutForOrder,
  CancelOrder,
  FinishOrder,
  GetOrderById,
  GetPaymentMethodsForOrder,
  GetPostOrderData,
  InitOrder,
  ModifyOrderAttachForm,
  ModifyOrderSelectClient,
  ModifyOrderSetMedicalCategory,
  ModifyOrderSetPaymentMethods,
  ModifyOrderSpecifyAppointmentInformation,
} from "./Order.data";
import {
  ControlReason,
  OrderAnalyticsEvent,
  OrderState,
  stateDefaults,
  OrderContext,
} from "./Order.constants";

const TAG = "domain/Order";

const getOrder = flow([values, head, get("order")]);
const logger = LoggerFactory.get(TAG);
const recordGraphQLError = ary(1, recordError);

const linkingPathPrefix = "order/";
export function parseInitialOrderStateFromLinking(url) {
  if (!url) {
    return;
  }
  const parsedURL = parse(url);
  if (!parsedURL.path.startsWith(linkingPathPrefix)) {
    return;
  }
  const orderId = parseInt(parsedURL.path.replace(linkingPathPrefix, ""), 10);
  if (orderId) {
    return { orderId };
  }
}

export function OrderProvider({
  children,
  navigationReady,
  initialState,
  buildCompletedOrderAlert,
}) {
  const initialStateWithDefaults = {
    ...stateDefaults,
    ...initialState,
  };
  const [orderState, setOrderState] = useState(initialStateWithDefaults);
  const { orderId } = orderState;
  const { state: authState } = useAuth();
  const alert = useAlert();
  const skip = !orderId || authState !== AuthState.AUTHENTICATED;
  const {
    data: { order = null } = {},
    error,
    refetch,
  } = useQuery(GetOrderById, {
    variables: { id: orderId },
    skip,
  });

  const productType = get("product.type", order);
  const cancelledByNavigationRef = useRef(false);
  const client = useApolloClient();
  const prevOrder = usePrevious(order);

  const editOrderStage = useCallback(
    (orderStage) => {
      const flowStage =
        order.flowStages.find(
          ({ __typename }) =>
            __typename === orderStageToFlowStage(orderStage.__typename)
        ) || {};
      const { __typename: stageType, subStage } = flowStage;
      let route = stageTypeToRoute({ stageType, subStage });
      if (stageType === "FlowStageSetPaymentMethods") {
        route = stageTypeToRoute({
          stageType: "FlowStageSetPaymentMethods",
          subStage: "Primary",
        });
      }

      push(route, { orderId: order?.id });
    },
    [order]
  );

  const orderHasPaymentMethods = useMemo(() => {
    if (!order) {
      return;
    }
    return hasPaymentMethods(order);
  }, [order]);
  const orderHasEveryStageFilled = useMemo(() => {
    if (!order) {
      return;
    }
    return hasEveryStageFilled(order);
  }, [order]);

  const onActive = useCallback(() => refetch(), [refetch]);
  useAppState({
    onActive,
    skip,
  });

  useEffect(() => {
    const prevFlowStageId = prevOrder?.currentStageId;
    const {
      orderId,
      stageId: currentFlowStageId,
      stageType: nextStage,
      params,
    } = getPassPropsFromOrder(order);
    if (prevFlowStageId || !currentFlowStageId) {
      return;
    }
    const routeName = getCurrentNavigationRoute().name;
    const currentRouteFlowStage = find(
      ({ __typename }) => routeName.startsWith(__typename),
      order.flowStages
    );
    if (!isKnownFlowStage(nextStage)) {
      return;
    }
    const nextRoute = stageTypeToRoute({
      stageType: nextStage,
      subStage: params?.subStage,
    });
    const nextRouteParams = {
      orderId,
    };
    if (!currentRouteFlowStage) {
      navigate(nextRoute, nextRouteParams);
    } else if (
      currentRouteFlowStage.id !== currentFlowStageId &&
      !isStageCompleted(currentRouteFlowStage.id, order)
    ) {
      replace(nextRoute, nextRouteParams);
    }
  }, [order, prevOrder?.currentStageId]);

  const [cancelOrder] = useMutation(CancelOrder, {
    variables: { input: { id: orderId } },
  });

  useEffect(() => {
    if (!navigationReady || authState !== AuthState.AUTHENTICATED) {
      return;
    }
    return addNavigationListener("state", () => {
      const { orderId: orderIdRouteParam } =
        getCurrentNavigationRoute()?.params || {};
      if (orderIdRouteParam) {
        setOrderState({ orderId: Number(orderIdRouteParam) });
      } else if (order?.state === OrderState.INIT) {
        cancelOrder();
        cancelledByNavigationRef.current = true;
      }
    });
  }, [authState, cancelOrder, navigationReady, order?.state, orderId]);
  useEffect(() => {
    if (error) {
      goBack();
      setOrderState(stateDefaults);
    }
  }, [error]);
  useEffect(() => {
    if (order?.state === OrderState.FINISHED) {
      client.query({ query: GetPostOrderData }).then(({ data }) =>
        alert(
          buildCompletedOrderAlert({
            account: data?.account,
            recentlyCompletedOrderType: productType,
            onSecondaryActionPress: () =>
              navigate("EditAccountContactInfoScreen"),
          })
        )
      );
      logAnalyticsEvent(OrderAnalyticsEvent.COMPLETED_ORDER, {
        productType,
      });
      setErrorTrackingAttributes({
        recentlyCompletedOrderType: productType,
      });
    }
  }, [
    productType,
    order?.state,
    client,
    alert,
    buildCompletedOrderAlert,
    navigationReady,
  ]);
  useEffect(() => {
    if (includes(order?.state, [OrderState.CANCELED, OrderState.FINISHED])) {
      setOrderState(stateDefaults);
      if (!cancelledByNavigationRef.current) {
        navigate("AuthenticatedTabNavigatorScreen", {
          screen: "HomeTabScreen",
        });
      }
      cancelledByNavigationRef.current = false;
    }
  }, [order?.state]);

  const value = useMemo(
    () => ({
      ...orderState,
      order,
      orderHasEveryStageFilled,
      orderHasPaymentMethods,
      refetch,
      setOrderState,
      editOrderStage,
    }),
    [
      editOrderStage,
      order,
      orderHasEveryStageFilled,
      orderHasPaymentMethods,
      refetch,
      orderState,
    ]
  );

  return (
    <OrderContext.Provider value={value}>{children}</OrderContext.Provider>
  );
}

export function useOrder() {
  const order = useContext(OrderContext);
  if (order === undefined) {
    logger(
      "useOrder: Couldn't find a order object. Is your component inside an OrderProvider?",
      order,
      LOGGER_LEVEL_ERROR
    );
    return;
  }
  return order;
}

function useRouteOrderFlowStage(orderOverride) {
  const { order: contextOrder } = useOrder();
  const order = orderOverride || contextOrder;

  const routeName = useRef(useRoute().name).current;
  return find(
    ({ __typename: stageType }) => routeName.startsWith(stageType),
    order?.flowStages || []
  );
}

export function useOrderFlowStageParams() {
  const { orderId } = useOrder();
  const { state: authState } = useAuth();
  const skip = !orderId || authState !== AuthState.AUTHENTICATED;
  const orderQueryResult = useQuery(GetOrderById, {
    variables: { id: orderId },
    skip,
  });
  const { data: { order = null } = {} } = orderQueryResult;

  const routeFlowStage = useRouteOrderFlowStage(order);
  const currentStageId = routeFlowStage?.id;

  const forceLoading =
    skip || includes(order?.state, [OrderState.CANCELED, OrderState.FINISHED]);

  return {
    stageParams: useMemo(
      () =>
        order && !forceLoading
          ? getPassPropsFromOrder({ ...order, currentStageId })
          : undefined,
      [currentStageId, forceLoading, order]
    ),
    ...orderQueryResult,
    ...(forceLoading && { loading: true }),
  };
}
export function useOrderFlowStage(query, options) {
  const orderStageParamsResult = useOrderFlowStageParams();
  const { stageParams = {} } = orderStageParamsResult;
  const { stageId } = stageParams;
  const skip = !stageId;
  const stageQueryResult = useQuery(query, {
    ...(isFunction(options) ? options(stageParams) : options),
    skip,
  });

  if (skip) {
    return orderStageParamsResult;
  } else {
    return {
      ...stageQueryResult,
      stageParams: orderStageParamsResult.stageParams,
    };
  }
}

export function useInitOrder() {
  const { setOrderState } = useOrder();
  const [initOrderMutation, initOrderMutationResult] = useMutation(InitOrder, {
    onError: recordGraphQLError,
    onCompleted(data) {
      setOrderState({ orderId: data.initOrder.order.id });
    },
  });
  const initOrder = useCallback(
    async ({ id: productId, type: productType, subtype: productSubtype }) => {
      if (initOrderMutationResult.loading) {
        return;
      }
      logAnalyticsEvent(OrderAnalyticsEvent.INIT_ORDER, {
        productId,
        productType,
        productSubtype,
      });
      return await initOrderMutation({
        variables: { input: { productId } },
      });
    },
    [initOrderMutation, initOrderMutationResult.loading]
  );
  return [initOrder, initOrderMutationResult];
}

function useOrderStage(mutation, options) {
  const { order = {} } = useOrder();
  const { push } = useNavigation();
  const currentRouteFlowStage = useRouteOrderFlowStage();

  const onCompleted = useCallback(
    (data = {}) => {
      const {
        stageType: nextStage,
        orderId,
        params,
      } = getPassPropsFromOrder(getOrder(data));
      if (!nextStage || !isKnownFlowStage(nextStage)) {
        return;
      }
      push(
        stageTypeToRoute({
          stageType: nextStage,
          subStage: params?.subStage,
        }),
        { orderId }
      );
    },
    [push]
  );
  const [stageMutationOriginal, stageMutationResult] = useMutation(mutation, {
    onCompleted,
    ...options,
  });
  const stageMutation = useCallback(
    async (options) => {
      if (stageMutationResult.loading) {
        return;
      }
      return await stageMutationOriginal(
        defaultsDeep(
          {
            variables: {
              input: {
                orderId: order?.id,
                stageId: currentRouteFlowStage?.id,
              },
            },
          },
          options
        )
      );
    },
    [
      currentRouteFlowStage?.id,
      order?.id,
      stageMutationOriginal,
      stageMutationResult.loading,
    ]
  );
  return [stageMutation, stageMutationResult];
}

export function useSelectClientFlowStage() {
  const { order = {} } = useOrder();
  const { push } = useNavigation();
  const [selectClient, result] = useOrderStage(ModifyOrderSelectClient);
  const onAddChildSelected = useCallback(
    () =>
      push("FlowStageSelectClient_AddChildScreen", {
        orderId: order?.id,
      }),
    [order?.id, push]
  );
  const onChildSelected = useCallback(
    ({ countryUid }) =>
      selectClient({
        variables: {
          input: {
            forMe: false,
            otherPersonUid: countryUid,
          },
        },
      }),
    [selectClient]
  );
  const onMyselfSelected = useCallback(
    () =>
      selectClient({
        variables: {
          input: { forMe: true },
        },
      }),
    [selectClient]
  );
  return [
    { onAddChildSelected, onMyselfSelected, onChildSelected, selectClient },
    result,
  ];
}

export function useAttachFormFlowStage() {
  const [attachForm, result] = useOrderStage(ModifyOrderAttachForm);

  const [recentDataId, setRecentDataId] = useState(null);

  const attachFormDataId = useCallback(
    (formDataId) => {
      attachForm({
        variables: { input: { formDataId } },
      });
      setTimeout(() => setRecentDataId(formDataId), 200);
    },
    [attachForm]
  );

  return [{ attachForm, attachFormDataId, recentDataId }, result];
}

export function useSetMedicalCategoryFlowStage() {
  const { order = {} } = useOrder();
  const { navigate } = useNavigation();
  const [setMedicalCategory, result] = useOrderStage(
    ModifyOrderSetMedicalCategory
  );
  const route = useRoute();
  const medicalCategoryId = route?.params?.medicalCategoryId;
  const [freeFormSymptoms, setFreeFormSymptoms] = useState("");

  const saveFreeFormSymptoms = useCallback(
    () =>
      setMedicalCategory({
        variables: {
          input: {
            medicalCategoryId,
            freeFormSymptoms,
          },
        },
      }),
    [setMedicalCategory, medicalCategoryId, freeFormSymptoms]
  );
  const selectMedicalCategory = useCallback(
    ({ id: medicalCategoryId, enableFreeFormSymptoms }) => {
      if (enableFreeFormSymptoms) {
        navigate(
          stageTypeToRoute({
            stageType: "FlowStageSetMedicalCategory",
            subStage: "DescribeSymptoms",
          }),
          { orderId: order?.id, medicalCategoryId }
        );
      } else {
        setMedicalCategory({
          variables: {
            input: {
              medicalCategoryId,
            },
          },
        });
      }
    },
    [navigate, order?.id, setMedicalCategory]
  );
  return [
    {
      selectMedicalCategory,
      freeFormSymptoms,
      setFreeFormSymptoms,
      saveFreeFormSymptoms,
    },
    result,
  ];
}

export function useSpecifyAppointmentInformationFlowStage() {
  const { navigate } = useNavigation();
  const { stageParams = {} } = useOrderFlowStageParams();
  const [specifyAppointmentInformation, result] = useOrderStage(
    ModifyOrderSpecifyAppointmentInformation
  );

  const selectDropIn = useCallback(
    () =>
      specifyAppointmentInformation({
        variables: { input: { now: true } },
      }),
    [specifyAppointmentInformation]
  );
  const selectAppointment = useCallback(
    () =>
      navigate(
        stageTypeToRoute({
          stageType: "FlowStageSpecifyAppointmentInformation",
          subStage: "SetProviderAndTime",
        }),
        { orderId: stageParams?.orderId }
      ),
    [navigate, stageParams?.orderId]
  );

  const initialSelectedProvider = get(
    "initialSelectedProvider",
    stageParams?.params
  );
  const [selectedProvider, setSelectedProvider] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [selectedScheduleItem, setSelectedScheduleItem] = useState();

  const saveSelectedScheduleItem = useCallback(async () => {
    try {
      await specifyAppointmentInformation({
        variables: {
          input: {
            now: false,
            scheduleItemUuid: selectedScheduleItem.uuid,
          },
        },
      });
    } catch (e) {
      if (!has("message", e)) {
        return;
      }
      showToast(e.message);
    } finally {
      setSelectedScheduleItem(null);
    }
  }, [
    specifyAppointmentInformation,
    setSelectedScheduleItem,
    selectedScheduleItem,
  ]);

  return [
    {
      specifyAppointmentInformation,
      selectAppointment,
      selectDropIn,
      initialSelectedProvider,
      saveSelectedScheduleItem,
      selectedProvider,
      setSelectedProvider,
      selectedDate,
      setSelectedDate,
      selectedScheduleItem,
      setSelectedScheduleItem,
    },
    result,
  ];
}

export function useSetPaymentMethodsFlowStage() {
  const client = useApolloClient();
  const { navigate, replace } = useNavigation();
  const { stageParams = {} } = useOrderFlowStageParams();
  const { orderId, product: { price: productPrice } = {} } = stageParams;
  const routeParams = useRoute()?.params || {};
  const primaryPaymentMethodId = routeParams.primaryPaymentMethodId
    ? Number(routeParams.primaryPaymentMethodId)
    : undefined;
  const controlPaymentMethodId = routeParams.controlPaymentMethodId
    ? Number(routeParams.controlPaymentMethodId)
    : undefined;

  const doesCoverProductPrice = useCallback(
    (paymentMethod) => doesCover(productPrice)(paymentMethod),
    [productPrice]
  );

  const onCompleted = useCallback(() => {
    navigate(stageTypeToRoute({ stageType: "FlowStageSetPaymentMethods" }), {
      orderId,
    });
  }, [navigate, orderId]);

  const [setPaymentMethods, result] = useOrderStage(
    ModifyOrderSetPaymentMethods,
    { onCompleted }
  );

  const choosePrimaryPaymentMethod = useCallback(() => {
    navigate(
      stageTypeToRoute({
        stageType: "FlowStageSetPaymentMethods",
        subStage: "Primary",
      }),
      {
        orderId,
      }
    );
  }, [navigate, orderId]);

  const maybeResolveDisabledPaymentMethods = useCallback(
    ({ accountPaymentMethodsForOrder }) => {
      const actionRequired = find(
        (accountPaymentMethod) =>
          accountPaymentMethod.disabled &&
          accountPaymentMethod.disabledResolutionUrl,
        accountPaymentMethodsForOrder
      );
      if (actionRequired) {
        navigate("DisabledPaymentMethodResolutionScreen", {
          orderId,
          navigationTitle: actionRequired.name,
          uri: actionRequired.disabledResolutionUrl,
        });
      }
    },
    [navigate, orderId]
  );

  const addInsuranceOrMembership = useCallback(
    () =>
      navigate(
        stageTypeToRoute({
          stageType: "FlowStageSetPaymentMethods",
          subStage: "SelectInsuranceOrMembership",
        }),
        {
          orderId,
        }
      ),
    [navigate, orderId]
  );
  const addCard = useCallback(
    () =>
      navigate(
        stageTypeToRoute({
          stageType: "FlowStageSetPaymentMethods",
          subStage: "AddCreditCard",
        }),
        {
          orderId,
        }
      ),
    [navigate, orderId]
  );
  const selectCard = useCallback(
    (card) =>
      setPaymentMethods({
        variables: {
          input: {
            cardId: card.id,
          },
        },
      }),
    [setPaymentMethods]
  );
  const selectPaymentProvider = useCallback(
    (paymentProvider) =>
      setPaymentMethods({
        variables: {
          input: {
            providerSlug: paymentProvider.slug,
          },
        },
      }),
    [setPaymentMethods]
  );

  const selectCardWithPartialPaymentMethod = useCallback(
    (card) =>
      setPaymentMethods({
        variables: {
          input: {
            cardId: card.id,
            discountIds: [primaryPaymentMethodId],
          },
        },
      }),
    [primaryPaymentMethodId, setPaymentMethods]
  );
  const selectPaymentProviderWithPartialPaymentMethod = useCallback(
    (paymentProvider) =>
      setPaymentMethods({
        variables: {
          input: {
            providerSlug: paymentProvider.slug,
            discountIds: [primaryPaymentMethodId],
          },
        },
      }),
    [primaryPaymentMethodId, setPaymentMethods]
  );

  const maybeSelectInsuranceOrMembership = useCallback(
    (insuranceOrDiscount) => {
      const controlQuestions = intersection(
        insuranceOrDiscount.controlQuestions,
        values(ControlReason)
      );
      if (insuranceOrDiscount.disabled) {
        replace(
          stageTypeToRoute({
            stageType: "FlowStageSetPaymentMethods",
            subStage: "Primary",
          }),
          {
            orderId,
          }
        );
      } else if (size(controlQuestions) > 0) {
        navigate(controlQuestionToRoute(head(controlQuestions)), {
          orderId,
          controlPaymentMethodId: insuranceOrDiscount.id,
        });
      } else if (doesCoverProductPrice(insuranceOrDiscount)) {
        setPaymentMethods({
          variables: {
            input: {
              discountIds: [insuranceOrDiscount.id],
            },
          },
        });
      } else {
        navigate(
          stageTypeToRoute({
            stageType: "FlowStageSetPaymentMethods",
            subStage: "Secondary",
          }),
          {
            orderId,
            primaryPaymentMethodId: insuranceOrDiscount.id,
          }
        );
      }
    },
    [doesCoverProductPrice, navigate, orderId, replace, setPaymentMethods]
  );

  const addSpecificInsuranceOrMembership = useCallback(
    async ({ slug }) => {
      navigate(
        stageTypeToRoute({
          stageType: "FlowStageSetPaymentMethods",
          subStage: "AddInsuranceOrDiscount",
        }),
        {
          orderId,
          slug,
        }
      );
    },
    [navigate, orderId]
  );

  const maybeSelectInsuranceOrMembershipByIds = useCallback(
    async (newlyAddedPaymentMethodsIds) => {
      const { data } = await client.query({
        query: GetPaymentMethodsForOrder,
        variables: {
          orderId,
        },
        fetchPolicy: "network-only",
      });
      const newlyAddedApplicablePaymentMethod = getApplicablePaymentMethodByIds(
        data?.accountPaymentMethodsForOrder,
        newlyAddedPaymentMethodsIds
      );
      if (newlyAddedApplicablePaymentMethod) {
        maybeSelectInsuranceOrMembership(newlyAddedApplicablePaymentMethod);
      }
    },
    [client, maybeSelectInsuranceOrMembership, orderId]
  );

  return [
    {
      setPaymentMethods,
      choosePrimaryPaymentMethod,
      maybeResolveDisabledPaymentMethods,
      addInsuranceOrMembership,
      addSpecificInsuranceOrMembership,
      addCard,
      selectCard,
      selectPaymentProvider,
      primaryPaymentMethodId,
      selectCardWithPartialPaymentMethod,
      selectPaymentProviderWithPartialPaymentMethod,
      maybeSelectInsuranceOrMembership,
      maybeSelectInsuranceOrMembershipByIds,
      controlPaymentMethodId,
    },
    result,
  ];
}

function useBooleanControlQuestion(mutation, options) {
  const { orderId = {} } = useOrder();
  const [{ maybeSelectInsuranceOrMembership, controlPaymentMethodId }] =
    useSetPaymentMethodsFlowStage();

  const onCompleted = useCallback(
    (data) => flow([values, head, maybeSelectInsuranceOrMembership])(data),
    [maybeSelectInsuranceOrMembership]
  );

  const [answerMutation, result] = useMutation(mutation, {
    onCompleted,
    ...options,
  });

  const answerPositively = useCallback(
    async (options) => {
      if (result.loading || !orderId) {
        return;
      }
      return await answerMutation({
        ...options,
        variables: {
          input: {
            orderId,
            answer: true,
            accountPaymentMethodId: controlPaymentMethodId,
          },
        },
      });
    },
    [answerMutation, controlPaymentMethodId, orderId, result.loading]
  );

  const answerNegatively = useCallback(
    async (options) => {
      if (result.loading || !orderId) {
        return;
      }
      return await answerMutation({
        ...options,
        variables: {
          input: {
            orderId,
            answer: false,
            accountPaymentMethodId: controlPaymentMethodId,
          },
        },
      });
    },
    [answerMutation, controlPaymentMethodId, orderId, result.loading]
  );

  return [
    { answerPositively, answerNegatively, controlPaymentMethodId, onCompleted },
    result,
  ];
}
export function useChildControlQuestion(options) {
  return useBooleanControlQuestion(
    AnswerChildControlForAccountPaymentMethod,
    options
  );
}

export function useWorkControlQuestion(options) {
  return useBooleanControlQuestion(
    AnswerWorkControlForAccountPaymentMethod,
    options
  );
}

export function useTravelControlQuestion() {
  const { orderId = {} } = useOrder();
  const { navigate } = useNavigation();
  const [{ maybeSelectInsuranceOrMembership, controlPaymentMethodId }] =
    useSetPaymentMethodsFlowStage();

  const onCompleted = useCallback(
    (data) => flow([values, head, maybeSelectInsuranceOrMembership])(data),
    [maybeSelectInsuranceOrMembership]
  );

  const [answerMutation, result] = useMutation(
    AnswerTravelControlForAccountPaymentMethod,
    {
      onCompleted,
    }
  );

  const answerWith = useCallback(
    async (answer, options = {}) => {
      if (result.loading || !orderId) {
        return;
      }
      return await answerMutation({
        ...options,
        variables: {
          input: {
            orderId,
            answer,
            accountPaymentMethodId: controlPaymentMethodId,
          },
        },
      });
    },
    [answerMutation, controlPaymentMethodId, orderId, result.loading]
  );

  const chooseDestinationCountry = useCallback(
    () =>
      navigate(
        stageTypeToRoute({
          stageType: "FlowStageSetPaymentMethods",
          subStage: "ControlQuestionTravelCountry",
        }),
        { orderId, controlPaymentMethodId }
      ),
    [controlPaymentMethodId, navigate, orderId]
  );

  return [
    {
      answerWith,
      chooseDestinationCountry,
      controlPaymentMethodId,
      onCompleted,
    },
    result,
  ];
}

export function useCancelOrder() {
  const { orderId } = useOrder();
  return useMutation(CancelOrder, {
    variables: { input: { id: orderId } },
  });
}

export function useFinishOrder() {
  const { order = {} } = useOrder();
  const { navigate, setParams } = useNavigation();
  const { getLinkingURLFromRoute } = useLinking();
  const orderId = order?.id;
  const route = useRoute();
  const {
    transaction_id: transactionIdParam,
    error: errorParam,
    merchant_reference: merchantReference,
  } = route.params || {};
  const [error, setError] = useState();
  const prevOrder = usePrevious(order);

  useEffect(() => {
    if (Platform.OS !== "ios") {
      return;
    }
    if (
      transactionIdParam ||
      errorParam ||
      order?.state === OrderState.FINISHED
    ) {
      WebBrowser.dismissBrowser();
    }
  }, [errorParam, order?.state, transactionIdParam]);

  const onCompleted = useCallback(
    ({ finishOrder: { checkoutUrl } }) => {
      const shouldUseWebView = checkoutUrl.startsWith(
        `${getAPI_URL().origin}/webview`
      );
      if (checkoutUrl) {
        // TODO <mdrakus>: remove once all payment gateways migrated from webviews
        if (shouldUseWebView) {
          navigate("CheckoutScreen", {
            checkoutUrl,
            orderId,
          });
        } else {
          if (Platform.OS === "web") {
            window.location.href = checkoutUrl;
          } else {
            WebBrowser.openBrowserAsync(checkoutUrl, {
              createTask: true,
              enableDefaultShareMenuItem: false,
              showInRecents: true,
            });
          }
        }
      }
    },
    [navigate, orderId]
  );
  const checkoutReturnUrl = getLinkingURLFromRoute(route);
  const [mutation, mutationResult] = useMutation(FinishOrder, {
    onCompleted,
    onError: recordGraphQLError,
    variables: {
      input: {
        id: orderId,
        checkoutReturnUrl: checkoutReturnUrl.toString(),
      },
    },
  });
  const { error: mutationError, loading: mutationLoading } = mutationResult;
  useEffect(() => {
    if (errorParam) {
      setError(errorParam);
    }
  }, [errorParam]);
  useEffect(() => {
    if (mutationError) {
      setError(mutationError?.message);
    }
  }, [mutationError]);
  useEffect(() => {
    if (transactionIdParam || errorParam || merchantReference) {
      setParams({
        error: undefined,
        merchant_reference: undefined,
        transaction_id: undefined,
      });
    }
  }, [errorParam, merchantReference, setParams, transactionIdParam]);
  useEffect(() => {
    if (prevOrder && prevOrder !== order) {
      setError(undefined);
    }
  }, [order, prevOrder]);
  useEffect(() => {
    if (mutationLoading) {
      setError(undefined);
    }
  }, [mutationLoading]);

  return [mutation, { ...mutationResult, error }];
}

export function useWebviewCheckoutForOrder() {
  const { navigate } = useNavigation();
  const route = useRoute();
  const { orderId, refetch } = useOrder();
  const { checkoutUrl = "" } = route.params || {};
  const [cancelCheckoutForOrder, result] = useMutation(CancelCheckoutForOrder, {
    variables: { input: { id: orderId } },
  });

  const failWith = useCallback(
    (error) => {
      cancelCheckoutForOrder();
      navigate({
        name: "FlowStageSetPaymentMethodsScreen",
        params: {
          error,
          orderId,
        },
        merge: true,
      });
    },
    [cancelCheckoutForOrder, navigate, orderId]
  );
  const handleWebViewMessage = useCallback(
    ({ nativeEvent }) => {
      safeHandlePostMessage(nativeEvent, logger, (data) => {
        const { status, error } = JSON.parse(data);
        switch (status) {
          case "ok":
            refetch();
            break;
          case "error":
            failWith(error);
            break;
          default:
        }
      });
    },
    [failWith, refetch]
  );

  return [
    {
      cancelCheckoutForOrder,
      checkoutUrl,
      handleWebViewMessage,
    },
    result,
  ];
}
