import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import PropTypes from "prop-types";
import {
  LOGGER_LEVEL_ERROR,
  LOGGER_LEVEL_WARN,
  LoggerFactory,
} from "@eyr-mobile/core/Logger";
import { CameraState, CameraType } from "@eyr-mobile/core/Camera";
import { MicrophoneState } from "@eyr-mobile/core/Microphone";
import {
  goBack,
  navigate,
  navigationRef,
  replace,
} from "@eyr-mobile/core/Navigation";
import { useIntl } from "@eyr-mobile/core/Intl";
import { logAnalyticsEvent } from "@eyr-mobile/core/Analytics";
import { Socket, getSocketURL } from "@eyr-mobile/core/Net";
import { gql, useQuery } from "@apollo/client";
import { Constants } from "@eyr-mobile/core/Config";
import { useSubscription } from "@eyr-mobile/core/DataProvider";
import { createAsync as createSoundAsync } from "@eyr-mobile/core/Audio";
import { VideoPublisher, VideoSubscriber } from "@eyr-mobile/core/Video";
import { useOnUnmount } from "@eyr-mobile/core/Lib";
import { Platform } from "react-native";

import { AuthState, useAuth } from "../Auth";

import { CallAnalyticsEvents } from "./Call.analytics";
import { CallEndedReasons, CallChannelMessages } from "./Call.constants";

const { version } = Constants.expoConfig;

const incomingCallFields = gql`
  fragment incomingCallFields on IncomingCall {
    callId
    callerName
    callerImageUrl
    callerTitle
    guardianToken
    locale
    opentokApiKey
    opentokSessionId
    opentokToken
  }
`;

const GetIncomingCall = gql`
  query GetIncomingCall {
    incomingCall {
      ...incomingCallFields
    }
  }
  ${incomingCallFields}
`;
const IncomingCallUpdatedSubscription = gql`
  subscription IncomingCallUpdatedSubscription {
    incomingCallUpdated {
      ...incomingCallFields
    }
  }
  ${incomingCallFields}
`;

const callStateDefaults = {
  callId: null,
  callerName: null,
  callerTitle: null,
  callerImageUrl: null,
  callerCameraState: CameraState.UNKNOWN,
  callerMicrophoneState: MicrophoneState.UNKNOWN,
  videoAPIKey: null,
  videoAPIToken: null,
  videoAPISessionId: null,
  videoAPIConnected: false,
  callAPIToken: null,
  callAPIConnected: false,
  accepted: false,
  declined: false,
  ended: false,
  microphoneEnabled: true,
  cameraEnabled: true,
  cameraType: CameraType.FRONT,
  MainVideoFrameComponent: VideoSubscriber,
  SecondaryVideoFrameComponent: VideoPublisher,
};

const logger = LoggerFactory.get("domain/Call");
const ongoingCallScreenRoute = "OngoingCallScreen";
const incomingCallScreenRoute = "IncomingCallScreen";
const inCall = () =>
  navigationRef.current?.getCurrentRoute()?.name === ongoingCallScreenRoute;
const inIncomingCall = () =>
  navigationRef.current?.getCurrentRoute()?.name === incomingCallScreenRoute;

const CallContext = createContext(callStateDefaults);

function getCallStateFromIncomingCall(incomingCall) {
  return {
    callId: incomingCall.callId,
    callerName: incomingCall.callerName,
    callerTitle: incomingCall.callerTitle,
    callerImageUrl: incomingCall.callerImageUrl,
    videoAPIToken: incomingCall.opentokToken,
    videoAPIKey: incomingCall.opentokApiKey,
    videoAPISessionId: incomingCall.opentokSessionId,
    callAPIToken: incomingCall.guardianToken,
  };
}

export const CallProvider = (props) => {
  const { children, initialState, ringtoneSource } = props;
  const initialStateWithDefaults = {
    ...callStateDefaults,
    ...initialState,
  };
  const [callState, setCallState] = useState(initialStateWithDefaults);
  const {
    callId,
    callAPIConnected,
    callerCameraState,
    callerMicrophoneState,
    accepted,
    ended,
    declined,
    callAPIToken,
    callerName,
    microphoneEnabled,
    cameraEnabled,
    cameraType,
  } = callState;

  const ringtoneRef = useRef(null);
  const socketRef = useRef(null);
  const channelRef = useRef(null);
  const acceptedRef = useRef(false);
  const declinedRef = useRef(false);
  const intl = useIntl();
  const { formatMessage } = intl;
  const { state: authState } = useAuth();
  const notAuthenticated = authState !== AuthState.AUTHENTICATED;

  const { data: { incomingCall } = {}, client } = useQuery(GetIncomingCall, {
    skip: notAuthenticated,
  });

  const handleIncomingCall = useCallback((incomingCall) => {
    logger("handleIncomingCall", incomingCall);
    setCallState((call) => {
      if (call.callId) {
        logger("already in call, ignoring new calls");
        return call;
      }
      return {
        ...call,
        ...getCallStateFromIncomingCall(incomingCall),
      };
    });
  }, []);

  const reportVideoAPIEvent = useCallback((event) => {
    const channel = channelRef.current;
    if (!channel) {
      return;
    }
    channel.push(CallChannelMessages.REPORT_VIDEO_API_EVENT, event);
  }, []);

  const resetCall = useCallback(() => {
    setCallState(callStateDefaults);
    client.writeQuery({
      query: GetIncomingCall,
      data: {
        incomingCall: null,
      },
    });
  }, [client]);

  const setMicrophoneEnabled = useCallback(
    (microphoneEnabled) =>
      setCallState((call) => ({ ...call, microphoneEnabled })),
    [setCallState]
  );

  useEffect(() => {
    if (!callId) {
      return;
    }
    logger("microphoneEnabled", microphoneEnabled);
  }, [callId, microphoneEnabled]);

  const setCameraEnabled = useCallback(
    (cameraEnabled) =>
      setCallState((call) => ({
        ...call,
        cameraEnabled,
      })),
    [setCallState]
  );

  useEffect(() => {
    if (!callId) {
      return;
    }
    logger("cameraEnabled", cameraEnabled);
  }, [callId, cameraEnabled]);

  const setCameraType = useCallback(
    (cameraType) =>
      setCallState((call) => ({
        ...call,
        cameraType,
      })),
    [setCallState]
  );

  useEffect(() => {
    if (!callId) {
      return;
    }
    logger("cameraType", cameraType);
  }, [callId, cameraType]);

  const answerCall = useCallback(() => {
    if (!callId) {
      logger(
        "answerCall",
        "unable to find the call, this is noop",
        LOGGER_LEVEL_WARN
      );
      return;
    }
    setCallState((call) => ({ ...call, accepted: true }));
  }, [callId]);

  const setCallerCameraState = useCallback((callerCameraState) => {
    setCallState((call) => ({ ...call, callerCameraState }));
  }, []);

  const setCallerMicrophoneState = useCallback((callerMicrophoneState) => {
    setCallState((call) => ({ ...call, callerMicrophoneState }));
  }, []);

  const setVideoAPIConnected = useCallback((videoAPIConnected) => {
    setCallState((call) => ({ ...call, videoAPIConnected }));
  }, []);

  useEffect(() => {
    if (!callId) {
      return;
    }
    logger("callerMicrophoneState", callerMicrophoneState);
  }, [callId, callerMicrophoneState]);
  useEffect(() => {
    if (!callId) {
      return;
    }
    logger("callerCameraState", callerCameraState);
  }, [callId, callerCameraState]);

  const createRingtone = useCallback(
    async (source, initialStatus, onPlaybackStatusUpdate, downloadFirst) => {
      try {
        const { sound } = await createSoundAsync(
          source,
          initialStatus,
          onPlaybackStatusUpdate,
          downloadFirst
        );
        ringtoneRef.current = sound;
      } catch (error) {
        logger("CallContext.web error", error, LOGGER_LEVEL_ERROR);
      }
    },
    []
  );

  const unloadRingtone = useCallback(async () => {
    if (!ringtoneRef.current) {
      return;
    }
    try {
      await ringtoneRef.current.unloadAsync();
      ringtoneRef.current = null;
    } catch (error) {
      logger("CallContext.web error", error, LOGGER_LEVEL_ERROR);
    }
  }, []);

  useEffect(() => {
    if (callAPIConnected && !accepted) {
      createRingtone(ringtoneSource, { shouldPlay: true, isLooping: true });
    }
  }, [callAPIConnected, accepted, createRingtone, ringtoneSource]);

  useEffect(() => {
    if (!callAPIConnected || accepted || declined) {
      unloadRingtone();
    }
  }, [callAPIConnected, accepted, declined, unloadRingtone]);

  useEffect(() => {
    if (accepted) {
      logger("answerCall");
      logAnalyticsEvent(CallAnalyticsEvents.CALL_ACCEPTED, { callId });
      acceptedRef.current = true;
    }
  }, [accepted, callId]);

  useEffect(() => {
    if (callAPIConnected && accepted) {
      const channel = channelRef.current;
      if (!channel) {
        return;
      }
      channel.push(CallChannelMessages.TRIGGER_CALL_ACCEPTED);
    }
  }, [accepted, callAPIConnected]);

  useEffect(() => {
    if (callAPIConnected && accepted) {
      replace(ongoingCallScreenRoute);
    }
    if (callAPIConnected && !accepted) {
      navigate(incomingCallScreenRoute);
    }
  }, [callAPIConnected, accepted, callerName, formatMessage]);

  useEffect(() => {
    if (!callId || !accepted) {
      return;
    }
    logger("microphoneEnabled", microphoneEnabled);
  }, [microphoneEnabled, callId, accepted]);

  const endCall = useCallback(() => {
    if (!callId) {
      logger(
        "endCall",
        "unable to find the call, this is noop",
        LOGGER_LEVEL_WARN
      );
      return;
    }
    setCallState((call) => ({ ...call, ended: true }));
  }, [callId]);

  useEffect(() => {
    if (ended) {
      logger("endCall");
    }
  }, [ended]);

  useEffect(() => {
    if (callAPIConnected && ended) {
      const channel = channelRef.current;
      if (!channel) {
        return;
      }
      channel.push(CallChannelMessages.TRIGGER_END, {
        reason: "end",
      });
    }
  }, [callAPIConnected, ended]);

  const declineCall = useCallback(() => {
    if (!callId) {
      logger(
        "declineCall",
        "unable to find the call, this is noop",
        LOGGER_LEVEL_WARN
      );
      return;
    }
    setCallState((call) => ({ ...call, declined: true }));
  }, [callId]);

  useEffect(() => {
    if (declined) {
      logger("declineCall");
      logAnalyticsEvent(CallAnalyticsEvents.CALL_DECLINED, { callId });
      declinedRef.current = true;
    }
  }, [declined, callId]);

  useEffect(() => {
    if (callAPIConnected && declined) {
      const channel = channelRef.current;
      if (!channel) {
        return;
      }
      channel.push(CallChannelMessages.TRIGGER_END, {
        reason: "decline",
      });
    }
  }, [callAPIConnected, declined]);

  useEffect(() => {
    if (!incomingCall) {
      return;
    }
    handleIncomingCall(incomingCall);
  }, [handleIncomingCall, incomingCall]);

  useSubscription(IncomingCallUpdatedSubscription, {
    skip: notAuthenticated,
    onData: ({ data: subscriptionData, client }) =>
      client.writeQuery({
        query: GetIncomingCall,
        data: {
          incomingCall: subscriptionData.data.incomingCallUpdated,
        },
      }),
  });

  useOnUnmount(() => {
    if (callAPIConnected && accepted) {
      const channel = channelRef.current;
      if (!channel) {
        return;
      }
      channel.push(CallChannelMessages.TRIGGER_END, {
        reason: "end",
      });
    }
  }, [callAPIConnected, accepted]);

  useEffect(() => {
    if (!callId) {
      return;
    }
    const socket = new Socket(getSocketURL(), {
      logger: (kind, message, data) => {
        logger(kind, { message, data });
      },
      params: {
        token: callAPIToken,
        version,
        platform: Platform.OS,
        platformVersion: Platform.Version,
      },
    });
    socketRef.current = socket;
    socket.onOpen(() => {
      const channel = socket.channel(`calls:${callId}`);
      channel
        .join()
        .receive("error", (response) => {
          const TAG = "channel.join()";
          if (response === CallEndedReasons.CALL_ACCEPTED_ELSEWHERE) {
            logger(
              TAG,
              {
                response,
                callId,
              },
              LOGGER_LEVEL_WARN
            );
            logAnalyticsEvent(CallAnalyticsEvents.CALL_ACCEPTED_ELSEWHERE, {
              callId,
            });
          } else if (response === CallEndedReasons.CALL_ENDED_WITH_NO_CONTEXT) {
            logger(
              TAG,
              {
                response,
                callId,
              },
              LOGGER_LEVEL_WARN
            );
            logAnalyticsEvent(
              CallAnalyticsEvents.CALL_DECLINED_ELSEWHERE_OR_ENDED_BY_CALLING_PARTY,
              {
                callId,
              }
            );
          } else {
            logger(TAG, response, LOGGER_LEVEL_ERROR);
            logAnalyticsEvent(CallAnalyticsEvents.CALL_SETUP_FAILED, {
              response,
              callId,
            });
          }
          resetCall();
        })
        .receive("ok", () =>
          setCallState((call) => ({ ...call, callAPIConnected: true }))
        );
      channelRef.current = channel;
    });
    socket.connect();

    return () => {
      function cleanup() {
        logAnalyticsEvent(CallAnalyticsEvents.CALL_ENDED, { callId });
        acceptedRef.current = false;
        declinedRef.current = false;
        channelRef.current = null;
        socket.disconnect();
        socketRef.current = null;
        if (inCall() || inIncomingCall()) {
          goBack();
        }
      }
      if (channelRef.current) {
        channelRef.current
          .leave()
          .receive("ok", cleanup)
          .receive("timeout", cleanup)
          .receive("error", cleanup);
      }
    };
  }, [callId, resetCall, callAPIToken]);

  useEffect(() => {
    const channel = channelRef?.current;
    if (!callAPIConnected || accepted || !channel) {
      return;
    }
    const handleCallAcceptedElsewhereSubscriptionRef = channel.on(
      CallChannelMessages.HANDLE_CALL_ACCEPTED_ELSEWHERE,
      () => {
        logger(CallEndedReasons.CALL_ACCEPTED_ELSEWHERE, {
          callId,
        });
        logAnalyticsEvent(CallAnalyticsEvents.CALL_ACCEPTED_ELSEWHERE, {
          callId,
        });
        resetCall();
      }
    );
    return () => {
      if (!channel) {
        return;
      }
      channel.off(
        CallChannelMessages.HANDLE_CALL_ACCEPTED_ELSEWHERE,
        handleCallAcceptedElsewhereSubscriptionRef
      );
    };
  }, [accepted, callId, callAPIConnected, resetCall]);

  useEffect(() => {
    const channel = channelRef?.current;
    if (!callAPIConnected || !channel) {
      return;
    }
    const handleEndSubscriptionRef = channel.on(
      CallChannelMessages.HANDLE_END,
      ({ response: { ended_reason } }) => {
        const acceptedOrDeclined = acceptedRef.current || declinedRef.current;
        if (ended_reason === CallEndedReasons.CALL_ENDED_BY_CALLING_PARTY) {
          logger("call_ended_by_calling_party");
          logAnalyticsEvent(CallAnalyticsEvents.CALL_ENDED_BY_CALLING_PARTY, {
            callId,
          });
        }
        if (ended_reason === CallEndedReasons.CALL_ENDED_BY_CALLED_PARTY) {
          if (acceptedOrDeclined) {
            logger("call_ended_by_called_party");
            logAnalyticsEvent(CallAnalyticsEvents.CALL_ENDED_BY_CALLED_PARTY, {
              callId,
            });
          } else {
            logger("call_declined_elsewhere");
            logAnalyticsEvent(CallAnalyticsEvents.CALL_DECLINED_ELSEWHERE);
          }
        }
        if (ended_reason === CallEndedReasons.CALL_TIMED_OUT) {
          logger("call_timed_out");
          logAnalyticsEvent(CallAnalyticsEvents.CALL_TIMED_OUT, {
            callId,
          });
        }
        resetCall();
      }
    );
    return () => {
      if (!channel) {
        return;
      }
      channel.off(CallChannelMessages.HANDLE_END, handleEndSubscriptionRef);
    };
  }, [callId, callAPIConnected, resetCall]);

  return (
    <CallContext.Provider
      value={{
        setMicrophoneEnabled,
        setCameraEnabled,
        setCameraType,
        setCallerCameraState,
        setCallerMicrophoneState,
        setVideoAPIConnected,
        endCall,
        answerCall,
        declineCall,
        reportVideoAPIEvent,
        ...callState,
      }}
    >
      {children}
    </CallContext.Provider>
  );
};

CallProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialState: PropTypes.object,
  buildMissedCallNotification: PropTypes.func.isRequired,
  ringtoneSource: PropTypes.string.isRequired,
};

export const useCall = () => {
  const call = useContext(CallContext);
  if (call === undefined) {
    logger(
      "useCall: Couldn't find a call object. Is your component inside a CallProvider?",
      call,
      LOGGER_LEVEL_ERROR
    );
    return;
  }
  return call;
};
